import React, { useState, FC, useEffect } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputLabel,
  FormControl,
  FormHelperText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  InputAdornment,
  Alert,
  OutlinedInput,
} from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  useCreateOpportunityMutation,
  useGetCognitoAccountQuery,
  CountryCodeEnum,
  useListOpportunitySoltuionsQuery,
} from "../../generated";
import useAccount from "./../../hooks/useAccount";
import COUNTRY_CODE from './CountryCodes';
import PartnerProducts from "./PartnerProducts.json";
// list of industries
const industryOptions = [
  'Aerospace',
  'Agriculture',
  'Automotive',
  'Computers and Electronics',
  'Consumer Goods',
  'Education',
  'Energy - Oil & Gas',
  'Energy - Power & Utilities',
  'Financial Services',
  'Gaming',
  'Government',
  'Healthcare',
  'Hospitality',
  'Life Sciences',
  'Manufacturing',
  'Marketing and Advertising',
  'Media and Entertainment',
  'Mining',
  'Non-Profit Organization',
  'Other',
  'Professional Services',
  'Real Estate and Construction',
  'Retail',
  'Software and Internet',
  'Telecommunications',
  'Transportation and Logistics',
  'Travel',
  'Wholesale and Distribution',
];

const prioritizedCountries = [
  { code: "AU", name: "Australia" },
  { code: "NZ", name: "New Zealand" },
  { code: "US", name: "United States" },
  { code: "CA", name: "Canada" },
  { code: "GB", name: "United Kingdom" },
  { code: "IN", name: "India" },
  { code: "AE", name: "United Arab Emirates" },
  { code: "SA", name: "Saudi Arabia" },
  { code: "QA", name: "Qatar" },
  { code: "KW", name: "Kuwait" },
];

interface PartnerProduct {
  Identifier: string;
  Name: string;
  Family: string;
  Description: string;
};

interface Solution {
  Arn: string;
  Catalog: string;
  Category: string;
  CreatedDate: string; // Using string to store datetime as JSON does not support `Date` type
  Id: string;
  Name: string;
  Status: "Active" | "Inactive" | "Draft";
}

// Validation schema using Yup
const validationSchema = Yup.object({
  companyName: Yup.string().required('Company name is required'),
  industryVertical: Yup.string().required('Industry vertical is required'),
  websiteURL: Yup.string().required('Website URL is required'),
  countryCode: Yup.string().required('Country is required'),
  postalCode: Yup.string().required('Postal code is required'),
  workloadName: Yup.string().required("Project Title is required"),
  customerAddress: Yup.string(),
  customerCity: Yup.string(),
  customerEmail: Yup.string().email('Must be a valid email'),
  partnerEmail: Yup.string().email('Must be a valid email'),
  customerMobile: Yup.string()
  .nullable()
  .optional()
  .matches(
    /^\+[1-9]\d{1,14}$/,
    'Must be a valid international mobile number with country code',
  ),
  partnerMobile: Yup.string()
  .nullable()
  .optional()
  .matches(
    /^\+[1-9]\d{1,14}$/,
    'Must be a valid international mobile number with country code',
  ),
  partnerTitle: Yup.string(),
  projectType: Yup.string().required("Project Type is required."),
  partnerMarketingActivity: Yup.bool(),
  partnerCampaignCode: Yup.string(),
  partnerFundingUsed: Yup.bool(),
  expectedCustomerSpend: Yup.number()
  .typeError('Must be a number')
  .positive('Must be a positive number'),
});

const formatCurrency = (value: number) => {
  if (!value) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(value);
};

const parseCurrency = (value: string) => {
  return Number(value.replace(/[^0-9.-]+/g, ''));
}

const validateEnumValue = <T extends Record<string, string>>(
  value: string,
  enumObject: T
): T[keyof T] => {
  if (Object.values(enumObject).includes(value)) {
    return value as T[keyof T];
  }
  throw new Error(`Invalid value: ${value}`);
};

type AwsWellArchitectedLodgementModalProps = {
  open: boolean;
  onClose: () => void;
};

export const AwsWellArchitectedLodgementModal: FC<AwsWellArchitectedLodgementModalProps> = ({
  open,
  onClose
}) => {
  const { arn, accounts } = useAccount();
  const { data: getUserdata } = useGetCognitoAccountQuery({
    variables: {},
    notifyOnNetworkStatusChange: true,
  });
  // Track createOpportunity mutation and its response
  const [createOpportunityMutation, { loading }] = useCreateOpportunityMutation();
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [createdOpportunityId, setCreatedOpportunityId] = useState<string | null>(null);
  const [selectedArn, setSelectedArn] = useState(arn);
  const [selectedPartnerProducts, setSelectedPartnerProducts] = useState<string[]>(["AWSSecurityHub", "AWSConfig"]);
  const [selectedSolutions, setSelectedSolutions] = useState<string[]>([]);
  const { data, refetch } = useListOpportunitySoltuionsQuery({
    context: {
      apiName: "well_architected"
    },
    variables: {
      arn: selectedArn,
    },
  });

  useEffect(() => {
    refetch({
      arn: selectedArn,
    })
  }, [selectedArn])

  const handleChange = (e: any) => {
    const value = e.target.value;
    const account = accounts.find((i: any) => i.arn === value);
    if (account) {
      setSelectedArn(account.arn);
    }else if (value === "6pl_account") {
      setSelectedArn(value);
    };
  };

  const handlePartnerProductChange = (event: any) => {
    setSelectedPartnerProducts(event.target.value);
  };

  const handleSolutionChange = (event: any) => {
    const value = event.target.value;
    const selection = typeof value === "string" ? value.split(",") : value;
    setSelectedSolutions(selection);
  };

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      companyName: '',
      industryVertical: '',
      websiteURL: '',
      workloadName: '',
      countryCode: '',
      postalCode: '',
      customerAddress: '',
      customerCity: '',
      customerFirstName: '',
      customerLastName: '',
      customerTitle: '',
      customerEmail: '',
      customerMobile: '',
      partnerFirstName: '',
      partnerLastName: '',
      partnerTitle: 'PartnerAccountManager',
      partnerEmail: '',
      partnerMobile: '',
      projectType: '',
      partnerMarketingActivity: false,
      partnerCampaignCode: '',
      partnerFundingUsed: false,
      nationalSecurity: false,
      expectedCustomerSpend: 500,
    },
    validationSchema,
    onSubmit: (values) => {
      console.log('Form Submitted', values);
    },
  });

  // Handler for the "Submit" button to call your GraphQL mutation
  const handleSubmit = async () => {
    const isValid = await formik.validateForm();
    console.log("Is Valid", isValid)
    if (Object.keys(isValid).length === 0) {
      const input: any = {
        WorkloadName: `${formik.values.projectType}-${formik.values.workloadName}`,
        SolutionsOffered: selectedSolutions,
        Products: selectedPartnerProducts,
        Customer: {
          Account: {
            CompanyName: formik.values.companyName,
            Industry: formik.values.industryVertical,
            WebsiteUrl: `https://${formik.values.websiteURL}`,
            Address: {
              CountryCode: formik.values.countryCode
                ? validateEnumValue(formik.values.countryCode, CountryCodeEnum)
                : null,
              PostalCode: formik.values.postalCode,
              City: formik.values.customerCity,
              StreetAddress: formik.values.customerAddress,
            },
          }
        },
        Project: {
          ExpectedCustomerSpend: [
            {
              Amount: formik.values.expectedCustomerSpend.toString(),
              CurrencyCode: 'USD',
              Frequency: 'Monthly',
              TargetCompany: formik.values.companyName
            },
          ],
        },
        Marketing: {
          AwsFundingUsed: formik.values.partnerFundingUsed ? 'Yes' : 'No',
          CampaignName: formik.values.partnerCampaignCode,
          Source: formik.values.partnerMarketingActivity ? 'Marketing Activity' : 'None',
        },
        NationalSecurity: formik.values.nationalSecurity,
      };

      if (formik.values.customerFirstName) {
        input.Customer.Contacts = [
          {
            FirstName: formik.values.customerFirstName,
            LastName: formik.values.customerLastName,
            BusinessTitle: formik.values.customerTitle,
            Email: formik.values.customerEmail,
            Phone: formik.values.customerMobile,
          }
        ];
      }

      if (formik.values.partnerFirstName) {
        input.OpportunityTeam = [
          {
            FirstName: formik.values.partnerFirstName,
            LastName: formik.values.partnerLastName,
            BusinessTitle: formik.values.partnerTitle,
            Email: formik.values.partnerEmail,
            Phone: formik.values.partnerMobile,
          }
        ];
      }
      console.log("Submitted input", input);
      try {
        const { data } = await createOpportunityMutation({
          context: {
            apiName: "well_architected"
          },
          variables: {
            arn: selectedArn,
            input,
          }
        });
        // If successful, extract the opportunityId and show a popup
        if (data?.createOpportunity?.opportunityId) {
          setCreatedOpportunityId(data.createOpportunity.opportunityId);
          setSuccessPopupOpen(true);
        }
        else if (data?.createOpportunity?.status == 1) {
          const errorMsg = data?.createOpportunity?.error;
          setErrorMessage(errorMsg);
          setErrorPopupOpen(true);
        }
      } catch (err: any) {
        console.error("Error creating opportunity: ", err);
        setErrorMessage(err.message || "An unexpected error occurred.");
        setErrorPopupOpen(true);
      }
    } else {
      formik.setTouched({
        companyName: true,
        industryVertical: true,
        websiteURL: true,
        countryCode: true,
        postalCode: true,
        customerEmail: true,
        partnerEmail: true
      });
    }
  };

  return (
    <>
      {/* Main dialog remains open on success (no onClose() in handleSubmit) */}
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle sx={{ pb: 0 }}>
          <b>AWS Well-Architected - ACE Opportunity Creation</b> <br/>
          Fill in the relevant information pertaining to the AWS Well-Architected opportunity. <br/>
          When you click SUBMIT, the information entered in the fields below will be used to create an ACE Opportunity in AWS Partner Central. <br/>
          Please ensure accuracy of the entered information.
        </DialogTitle>
        <DialogContent sx={{ pt: 2 }}>
        <Box component="form" onSubmit={formik.handleSubmit}>
          {/* ========== Section: Basic Company & Opportunity Info ========== */}
          <Paper elevation={1} sx={{ p: 3, mb: 3, border: '1px solid #ddd', borderRadius: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
              End Customer Details
            </Typography>

            <Grid container sx={{ m: 1, pb: 1, alignItems: "center" }}>
              {/* Select Input */}
              <Grid item md={6}>
                <Typography color="primary" fontWeight="bold">
                  Select your ACE Integrated AWS Account
                </Typography>
              </Grid>
              <Grid item md={6} sx={{pr: 1}}>
                <FormControl fullWidth>
                  <Select
                  id="account-select"
                  labelId="account-select-label"
                  value={selectedArn}
                  onChange={handleChange}
                  >
                    {
                      /*
                    <MenuItem key="six-pillars-account" value="6pl_account">
                      Create with 6pillars.ai
                    </MenuItem> 
                    */
                    }
                  {accounts.map((a: any) => {
                    let splits = a.arn.split(":");
                    return (
                      <MenuItem key={a.arn} value={a.arn}>
                        {`${a.accessnickname}-${splits[4]}`}
                      </MenuItem>
                    );
                  })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container sx={{ m: 1, pb: 1, alignItems: "center" }}>
              <Grid item md={6}>
                <Typography color="primary" fontWeight="bold">
                  Select your Project Type
                </Typography>
              </Grid>
              <Grid item md={6} sx={{pr: 1}}>
                <FormControl fullWidth>
                  <InputLabel 
                    id="parnter-project-multi-select-label"
                  >
                      Please select
                  </InputLabel>
                  <Select
                    id="parnter-project-type"
                    name="projectType"
                    value={formik.values.projectType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    input={<OutlinedInput label="Please select" />}
                    error={formik.touched.projectType && Boolean(formik.errors.projectType)}
                    labelId="parnter-project-multi-select-label"
                  >
                    <MenuItem key="project-type-1" value="Review">
                      Review
                    </MenuItem>
                    <MenuItem key="project-type-2" value="Discovery">
                      Discovery
                    </MenuItem>
                    <MenuItem key="project-type-3" value="Remediation">
                      Remediation
                    </MenuItem>
                  </Select>
                  {formik.touched.projectType && formik.errors.projectType && (
                    <FormHelperText>{formik.errors.projectType}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container sx={{ m: 1, pb: 1, alignItems: "center" }}>
              <Grid item md={6}>
                <Typography color="primary" fontWeight="bold">
                  Select your AWS Products
                </Typography>
              </Grid>
              <Grid item md={6} sx={{pr: 1}}>
              <FormControl fullWidth>
                <InputLabel id="multi-select-label">Select AWS Services</InputLabel>
                <Select
                  labelId="multi-select-label"
                  multiple
                  value={selectedPartnerProducts}
                  onChange={handlePartnerProductChange}
                  input={<OutlinedInput label="Select AWS Services" />}
                >
                  {PartnerProducts.map((service: PartnerProduct) => (
                    <MenuItem key={service.Identifier} value={service.Identifier}>
                      {service.Name} ({service.Family})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Grid>
            </Grid>
            <Grid container sx={{ m: 1, pb: 1, alignItems: "center" }}>
              <Grid item md={6}>
                <Typography color="primary" fontWeight="bold">
                  Select your Solutions
                </Typography>
              </Grid>
              <Grid item md={6} sx={{pr: 1}}>
                <FormControl fullWidth>
                  <Select
                    id="select-opportunity-solutions"
                    value={selectedSolutions}
                    multiple
                    onChange={handleSolutionChange}
                  >
                    {(() => {
                    try {
                      const rawSolutions = data?.listOpportunitySoltuions?.solutions;
                      let solutions = typeof rawSolutions === "string" ? JSON.parse(rawSolutions) : rawSolutions;
                      if (typeof solutions === "string") { // if it's still a string, parse again
                        solutions = JSON.parse(solutions);
                      }
                      return solutions.map((solution: Solution) => (
                        <MenuItem key={solution.Id} value={solution.Id}>
                          {solution.Name}
                        </MenuItem>
                      ));
                    } catch (error) {
                      console.error("Error parsing solutions JSON:", error);
                      return null;
                    }
                  })()}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="workloadName"
                    name="workloadName"
                    label="Project Title"
                    placeholder="PROD-Workload Name"
                    value={formik.values.workloadName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.workloadName && Boolean(formik.errors.workloadName)}
                    helperText={formik.touched.workloadName && formik.errors.workloadName}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {getUserdata?.getCognitoAccount?.organisation}-
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Alert severity="info">Please ensure that this project title matches the well-architected workload name.</Alert>
                </Grid>
                {/* Company Name (Required) */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="companyName"
                    name="companyName"
                    label="End Customer Organisation/Company Name *"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                    helperText={formik.touched.companyName && formik.errors.companyName}
                  />
                </Grid>
                {/* Industry (Required) */}
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={formik.touched.industryVertical && Boolean(formik.errors.industryVertical)}
                  >
                    <InputLabel id="industryVertical-label">Industry *</InputLabel>
                    <Select
                      labelId="industryVertical-label"
                      id="industryVertical"
                      name="industryVertical"
                      label="Industry *"
                      value={formik.values.industryVertical}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value="">
                        <em>-- Select an industry --</em>
                      </MenuItem>
                      {industryOptions.map((option, idx) => (
                        <MenuItem value={option} key={idx}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.industryVertical && formik.errors.industryVertical}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* Website URL (Required) */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="websiteURL"
                    name="websiteURL"
                    label="Website URL *"
                    placeholder="www.example.com"
                    value={formik.values.websiteURL}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.websiteURL && Boolean(formik.errors.websiteURL)}
                    helperText={formik.touched.websiteURL && formik.errors.websiteURL}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          https://
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                {/* Country Code (Required) */}
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                  >
                    <InputLabel id="countryCode-label">Country *</InputLabel>
                    <Select
                      labelId="countryCode-label"
                      id="countryCode"
                      name="countryCode"
                      label="Country *"
                      value={formik.values.countryCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value="">
                        <em>-- Select your country --</em>
                      </MenuItem>
                      {/* Prioritized Countries Section */}
                      {prioritizedCountries.map((country, idx) => (
                        <MenuItem value={country.code} key={`priority-${idx}`}>
                          {`${country.code} - ${country.name}`}
                        </MenuItem>
                      ))}
                      {/* Separator */}
                      <MenuItem disabled>──────────</MenuItem>
                      {/* Remaining Countries in Alphabetical Order */}
                      {Object.values(CountryCodeEnum)
                        .filter((code) => !["AU", "NZ", "US", "CA", "GB", "IN", "AE", "SA", "QA", "KW"].includes(code))
                        .sort((a, b) => {
                          const nameA = COUNTRY_CODE[a] || a; // Fallback to the code if name is undefined
                          const nameB = COUNTRY_CODE[b] || b;
                          return nameA.localeCompare(nameB);
                        })
                        .map((countryCode, idx) => (
                          <MenuItem value={countryCode} key={`regular-${idx}`}>
                            {`${countryCode} - ${COUNTRY_CODE[countryCode]}`}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.countryCode && formik.errors.countryCode}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* Postal Code (Required) */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="postalCode"
                    name="postalCode"
                    label="Postal Code *"
                    value={formik.values.postalCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                    helperText={formik.touched.postalCode && formik.errors.postalCode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="customerAddress"
                    name="customerAddress"
                    label="Address (Optional)"
                    value={formik.values.customerAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                    helperText={formik.touched.postalCode && formik.errors.postalCode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="customerCity"
                    name="customerCity"
                    label="City (Optional)"
                    value={formik.values.customerCity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                    helperText={formik.touched.postalCode && formik.errors.postalCode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={<Checkbox checked={formik.values.nationalSecurity} />}
                    label="Is this a Public Sector customer? *by ticking this you accept that you understand AWS terms in relation to submitting AWS opportunties for public sector customers."
                    name="nationalSecurity"
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
            </Paper>
            {/* ========== Section: Customer Details (Optional) ========== */}
            <Paper elevation={0} sx={{ p: 2, mb: 3, border: '1px solid #ddd' }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
                End Customer Contact Details (Optional - Recommended)
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="customerFirstName"
                    name="customerFirstName"
                    label="First Name"
                    value={formik.values.customerFirstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.customerFirstName && Boolean(formik.errors.customerFirstName)}
                    helperText={formik.touched.customerFirstName && formik.errors.customerFirstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="customerLastName"
                    name="customerLastName"
                    label="Last Name"
                    value={formik.values.customerLastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.customerLastName && Boolean(formik.errors.customerLastName)}
                    helperText={formik.touched.customerLastName && formik.errors.customerLastName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    id="customerTitle"
                    name="customerTitle"
                    label="Customer Title"
                    value={formik.values.customerTitle}
                    onBlur={formik.handleBlur}
                    error={formik.touched.customerTitle && Boolean(formik.errors.customerTitle)}
                    helperText={formik.touched.customerTitle && formik.errors.customerTitle}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="customerEmail"
                    name="customerEmail"
                    label="Email Address"
                    value={formik.values.customerEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.customerEmail && Boolean(formik.errors.customerEmail)}
                    helperText={formik.touched.customerEmail && formik.errors.customerEmail}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="customerMobile"
                    name="customerMobile"
                    label="Mobile Number (Optional)"
                    value={formik.values.customerMobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.customerMobile && Boolean(formik.errors.customerMobile)}
                    helperText={formik.touched.customerMobile && formik.errors.customerMobile}
                  />
                </Grid>
              </Grid>
            </Paper>
            {/* ========== Section: Partner Details (Optional) ========== */}
            <Paper elevation={0} sx={{ p: 2, mb: 3, border: '1px solid #ddd' }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
                Partner Details (Optional)
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="partnerFirstName"
                    name="partnerFirstName"
                    label="First Name"
                    value={formik.values.partnerFirstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.partnerFirstName && Boolean(formik.errors.partnerFirstName)}
                    helperText={formik.touched.partnerFirstName && formik.errors.partnerFirstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="partnerLastName"
                    name="partnerLastName"
                    label="Last Name"
                    value={formik.values.partnerLastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.partnerLastName && Boolean(formik.errors.partnerLastName)}
                    helperText={formik.touched.partnerLastName && formik.errors.partnerLastName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="partnerEmail"
                    name="partnerEmail"
                    label="Email Address"
                    value={formik.values.partnerEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.partnerEmail && Boolean(formik.errors.partnerEmail)}
                    helperText={formik.touched.partnerEmail && formik.errors.partnerEmail}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="partnerMobile"
                    name="partnerMobile"
                    label="Mobile Number (Optional)"
                    value={formik.values.partnerMobile}
                    onBlur={formik.handleBlur}
                    error={formik.touched.partnerMobile && Boolean(formik.errors.partnerMobile)}
                    helperText={formik.touched.partnerMobile && formik.errors.partnerMobile}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={0} sx={{ p: 2, mb: 3, border: '1px solid #ddd' }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
                Marketing Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="expectedCustomerSpend"
                    name="expectedCustomerSpend"
                    label="Expected monthly spend (USD)"
                    value={formik.values.expectedCustomerSpend}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      )
                    }}
                    onChange={(event) => {
                      const numericValue = parseCurrency(event.target.value);
                      formik.setFieldValue('expectedCustomerSpend', numericValue);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.expectedCustomerSpend && Boolean(formik.errors.expectedCustomerSpend)}
                    helperText={formik.touched.expectedCustomerSpend && formik.errors.expectedCustomerSpend}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormLabel id="aws-mdf-radio-buttons-group">AWS MDF & Campaign Tracking required?</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="aws-mdf-radio-buttons-group"
                      name="partnerMarketingActivity"
                      value={formik.values.partnerMarketingActivity}
                      onChange={(event) => formik.setFieldValue("partnerMarketingActivity", event.target.value === "true")}
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Yes" />
                      <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="partnerCampaignCode"
                    name="partnerCampaignCode"
                    label="AWS Campaign Code"
                    value={formik.values.partnerCampaignCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.partnerCampaignCode && Boolean(formik.errors.partnerCampaignCode)}
                    helperText={formik.touched.partnerCampaignCode && formik.errors.partnerCampaignCode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormLabel id="aws-mdf-funding-radio-buttons-group">Was AWS MDF used on this opportunity?</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="aws-mdf-funding-radio-buttons-group"
                      name="partnerFundingUsed"
                      value={formik.values.partnerFundingUsed}
                      onChange={(event) => formik.setFieldValue("partnerFundingUsed", event.target.value === "true")}
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Yes" />
                      <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={loading}
            loading={loading}
            loadingIndicator={
              <CircularProgress color={"secondary"} size={22} />
            }
            onClick={handleSubmit}
          >
            Submit Draft
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Success Popup Dialog */}
      <Dialog
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
      >
        <DialogTitle>Opportunity Created</DialogTitle>
        <DialogContent>
          {createdOpportunityId ? (
            <Typography>
              Your new Opportunity ID is: <strong>{createdOpportunityId}</strong>
            </Typography>
          ) : (
            <Typography>Opportunity created successfully!</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessPopupOpen(false)} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Popup */}
      <Dialog open={errorPopupOpen} onClose={() => setErrorPopupOpen(false)}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography color="error">{errorMessage}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setErrorPopupOpen(false)} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
    </>
  );
};

export default AwsWellArchitectedLodgementModal;