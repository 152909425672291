import { FC, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useListWorkloadsQuery } from "../../../generated";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Grid,
  Button,
  Container,
  Pagination,
  InputLabel,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Box,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import StyledSelect from "../../common/StyledSelect";
import StyledTableCell from "../../common/StyledTableCell";
import ListWorkLoadIacItem from "./ListWorkLoadIacItem";
import useAccount from "../../../hooks/useAccount";
import Loader from "../../common/Loader";

interface ListWorkloadsIacProps {
  updateWorkLoad: (
    workLoadId: string,
    workLoadName: string,
    highRiskCount: number,
    initialHighRiskCount: number,
    awsWafrAccount: string,
  ) => void;
  setUnlockSteps: React.Dispatch<
    React.SetStateAction<{
      step2: boolean;
      step3: boolean;
      step4: boolean;
    }>
  >;
}

const ListWorkloadsIac: FC<ListWorkloadsIacProps> = ({
  updateWorkLoad,
  setUnlockSteps,
}: ListWorkloadsIacProps) => {
  const { accounts, arn } = useAccount();
  const initialArn = (() => {
    const account = accounts.find((i: any) => i.arn === arn);
    return account?.accounttype === "LITE" ? "6pl_account" : arn;
  })();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedArn, setSelectedArn] = useState(initialArn);
  const {data, loading, refetch} = useListWorkloadsQuery({
    variables: {
      arn: selectedArn,
      workloadType: "IaC",
    },
    context: {
      apiName: "well_architected",
    },
  });

  //Handle that locks all steps
  const handleUnlockSteps = () => {
    setUnlockSteps((prevState) => ({
      ...prevState,
      step2: false, // Lock step 2
      step3: false, // lock step 3
      step4: false, // lock step 4
    }));
  };

  useEffect(() => {
    // refetch({ limit: 100, nextToken: ""});
    refetch({ arn: selectedArn });
    handleUnlockSteps();
  }, [data, selectedArn, refetch]);

  /**
   * Handle Wafr ARN change
   * @param e wafr arn value
   */
  const handleWafrArn = (e: any) => {
    setSelectedArn(e.target.value);
  }

  return (
    <Container maxWidth="lg" component={Paper}>
      <Box sx={{ width: "100%" }}>
        <TableContainer component={Paper} sx={{ p: 4 }}>
          <Paper elevation={0} sx={{ p: 0 }}>
            <Grid container flexDirection="row">
              <Paper
                key={"1"}
                component="div"
                sx={{
                  p: "0px 0px",
                  mb: 0,
                  display: "flex",
                  border: 0,
                  boxShadow: 0,
                }}
              >
                <Typography
                  component={"span"}
                  variant="h6"
                  color="text.primary"
                  paragraph
                >
                  View in AWS Well-Architected Framework Console
                </Typography>
              </Paper>
              <Paper
                key={"2"}
                component="div"
                elevation={0}
                sx={{
                  p: "2px 4px",
                  mb: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  flexGrow: 1,
                }}
              >
                <Button
                  id="wat-setup-btn"
                  type="submit"
                  variant="contained"
                  endIcon={<OpenInNewIcon />}
                  href="//console.aws.amazon.com/wellarchitected/home"
                  target="_blank"
                  sx={{
                    width: 157,
                    height: 40,
                    bgcolor: "secondary.main",
                    "&:hover": {
                      bgcolor: "secondary.main",
                      color: "secondary.contrastText",
                    },
                  }}
                >
                  View
                </Button>
              </Paper>
            </Grid>
            <Grid container flexDirection="row" sx={{justifyContent: 'space-between'}}>
              <Paper
                key={"1"}
                component="div"
                elevation={0}
                sx={{
                  p: "0px 0px",
                  mb: 2,
                  display: "flex",
                  alignItems: "right",
                }}
              >
                <InputLabel id="page-size" sx={{ alignSelf: "center", p: 1 }}>
                  Show
                </InputLabel>
                <FormControl sx={{ m: 1, minWidth: 119 }} size="small">
                  <StyledSelect
                    labelId="page-size-select-label"
                    id="page-size-select"
                    value={rowsPerPage}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      setRowsPerPage(Number(e.target.value));
                      setPage(0);
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </StyledSelect>
                </FormControl>
              </Paper>
              <Paper
                key={"3"}
                component="div"
                elevation={0}
                sx={{
                  p: "0px 0px",
                  mb: 2,
                  display: "flex",
                  alignItems: "right",
                }}
              >
                <InputLabel id="page-size" sx={{ alignSelf: "center", p: 1 }}>
                  Select AWS WAFR Account
                </InputLabel>
                <FormControl sx={{ m: 1, minWidth: 119 }} size="small">
                  <StyledSelect
                    labelId="page-size-select-label"
                    id="page-size-select"
                    value={selectedArn}
                    onChange={handleWafrArn}
                  >
                    <MenuItem key="six-pillars-account" value="6pl_account">
                      Created with 6pillars.ai
                    </MenuItem>
                    {accounts.map((a: { arn: string, accessnickname: string, accounttype: string }) => {
                      const splits = a.arn.split(":");
                      return (
                        <MenuItem
                          key={`${a.accessnickname} - ${splits[4]}`}
                          value={a.arn}
                          disabled={a.accounttype === "LITE"}
                        >{`${a.accessnickname} - ${splits[4]}`}</MenuItem>
                      );
                    })}
                  </StyledSelect>
                </FormControl>
              </Paper>
            </Grid>
          </Paper>
          <Grid container flexDirection="row">
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Workload Name</StyledTableCell>
                  <StyledTableCell>Updated At</StyledTableCell>
                  <StyledTableCell>Milestones</StyledTableCell>
                  <StyledTableCell>HRI&apos;s</StyledTableCell>
                  <StyledTableCell>Update</StyledTableCell>
                  <StyledTableCell>Report</StyledTableCell>
                  <StyledTableCell>Delete</StyledTableCell>
                  <StyledTableCell sx={{ width: "5%" }}/>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow key="listworkloads-loader">
                    <StyledTableCell colSpan={8}>
                      <Loader/>
                    </StyledTableCell>
                  </TableRow>
                ):(
                  data?.listWorkloads?.items
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((workload: any) => {
                    return (
                      <ListWorkLoadIacItem
                        key={workload.WorkloadId}
                        arn={selectedArn}
                        workload={workload}
                        updateWorkLoad={updateWorkLoad}
                      />
                    );
                  })
                )}
              </TableBody>
            </Table>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent={"flex-end"}
            sx={{ p: 0, pt: 2, pb: 2 }}
          >
            <Pagination
              count={
                data?.listWorkloads?.items.length
                  ? Math.ceil(data?.listWorkloads?.items.length / rowsPerPage)
                  : 0
              }
              variant="outlined"
              sx={{
                color: "primary.main",
                borderColor: "primary.main",
              }}
              shape="rounded"
              page={page + 1}
              onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                setPage(value - 1);
              }}
            />
          </Grid>
        </TableContainer>  
      </Box>
    </Container>
  );
};

export default ListWorkloadsIac;
