import { useCreateMilestoneMutation } from "../generated";

const useCreateMilestone = () => {

    const [createMilestoneMutation] = useCreateMilestoneMutation();

    const createMilestone = async (
			wafrArn: string,
			workloadid: string,
			milestonename: string,
		) => {
			const milestonecreate = await createMilestoneMutation({
				variables: {
					arn:wafrArn, // value for 'awsWafrAccount'
					workloadid,
					milestonename,
				},
				context: {
					apiName: "well_architected",
				},
				notifyOnNetworkStatusChange: true,
			});
			return milestonecreate;
    };
    return {createMilestone};
}

export { useCreateMilestone };