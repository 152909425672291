import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CrossAccountRegion from "./CrossAccountRegion";
import Alert from "@mui/material/Alert";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import ManualCrossAccount from "./ManualCrossAccount";
import DownloadTemplateModal from "./DownloadTemplateModal";

interface CrossAccountProps {
  setValue: any;
  setTabSwitch: any;
}
export default function CrossAccount({
  setValue,
  setTabSwitch,
}: CrossAccountProps) {
  const [accountType, setAccountType] = useState("READ_ONLY");
  const [deploymentType, setDeploymentType] = useState("AUTOMATED_DEPLOYMENT");
  const [accountCreated, setAccountCreated] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setAccountType(event.target.value as string);
  };

  const handleDeploymentTypeChange = (event: SelectChangeEvent) => {
    setDeploymentType(event.target.value as string);
  };

  return (
    <Container>
      <Box sx={{ pt: 4 }}>
        <Container sx={{ width: 1 }} component={Paper}>
          <Grid container spacing={2} alignItems="center">
            <Grid item md={12}>
              <Typography variant="h4" color="primary.main" gutterBottom>
                Deploy AUTOMATE+ to an AWS Account
              </Typography>
            </Grid>
            {accountType !== "" ? (
              <Grid item md={12}>
                <Alert variant="outlined" severity="info">
                  Before you deploy the cross account role, please ensure you
                  are logged into the AWS account that you wish to connect to
                  AUTOMATE+. Deployment type AUTOMATE+ includes all Continuous
                  Compliance features.
                </Alert>
              </Grid>
            ) : null}
            <Grid item md={12}>
              <FormControl fullWidth>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Deployment Method
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  defaultValue="READ_ONLY"
                  value={deploymentType}
                  onChange={handleDeploymentTypeChange}
                >
                  <FormControlLabel
                    value="AUTOMATED_DEPLOYMENT"
                    control={<Radio />}
                    label="Automated Deployment (Recommended) using cross account role."
                  />
                  <FormControlLabel
                    value="MANUAL_DEPLOYMENT"
                    control={<Radio />}
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>
                          Manual Deployment (Advanced Users) using external ID
                          and SNS topic ARN.
                        </Typography>
                        <DownloadTemplateModal />
                      </Box>
                    }
                  />
                  {deploymentType === "MANUAL_DEPLOYMENT" ? (
                    <Alert severity="warning">
                      IMPORTANT: Ensure that you have the external ID and the
                      SNS topic ARN with you. You will need these to complete
                      deployment.
                    </Alert>
                  ) : null}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <Typography variant="body2" color="text.primary" paragraph>
                Please select your preferred product version:
              </Typography>
              <Typography variant="body2" color="text.primary" paragraph>
                1. AUTOMATE LITE displays your findings only.
              </Typography>
              <Typography variant="body2" color="text.primary" paragraph>
                2. AUTOMATE is the Read-Only version without Continuous
                Compliance capabilities.
              </Typography>
              <Typography variant="body2" color="text.primary" paragraph>
                3. AUTOMATE+ Includes all Continuous Compliance features.
              </Typography>
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Product Version
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  defaultValue="READ_ONLY"
                  value={accountType}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="LITE"
                    disabled={deploymentType === "MANUAL_DEPLOYMENT"}
                    control={<Radio />}
                    label="AUTOMATE LITE (Findings Only: Requires AWS Security Hub and partners must also deploy AUTOMATE/+ into their AWS account)"
                  />
                  <FormControlLabel
                    value="READ_ONLY"
                    control={<Radio />}
                    label="AUTOMATE (Read Only: Recommended for New Users)"
                  />
                  <FormControlLabel
                    value="AUTOMATED"
                    disabled={deploymentType === "MANUAL_DEPLOYMENT"}
                    control={<Radio />}
                    label="AUTOMATE+ (Full Continuous Compliance Capability: Advanced Users)"
                  />
                  <Alert severity="warning">
                    IMPORTANT: Ensure you are not running AWS ASR (Automated
                    Security Response) in this account prior to deployment of
                    AUTOMATE+.
                  </Alert>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={12}></Grid>
          </Grid>
          {deploymentType === "AUTOMATED_DEPLOYMENT" ? (
            <CrossAccountRegion
              accountType={accountType}
              deploymentType={deploymentType}
              setValue={setValue}
              setAccountCreated={setAccountCreated}
              setTabSwitch={setTabSwitch}
            />
          ) : (
            <ManualCrossAccount
              accountType={accountType}
              deploymentType={deploymentType}
              setValue={setValue}
              setAccountCreated={setAccountCreated}
              setTabSwitch={setTabSwitch}
            />
          )}
        </Container>
      </Box>
    </Container>
  );
}
