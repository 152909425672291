import { useCreateWorkloadMutation } from "../generated";

const useCreateIacWorkload = () => {
  const [createWorkloadMutation] = useCreateWorkloadMutation();
  
  const createIacWorkload = async (
    workloadname: string,
    awsCreditAccountId: string,
    awsTechnicalReviewAccount: string,
    wafr_arn: string,
    region: string,
  ) => {
    const workloadcreation = await createWorkloadMutation({
      variables: {
        arn:wafr_arn,
        workloadname,
        description: `Mapping Security Hub with Well-Architected Tool (AWS Credit Account : ${awsCreditAccountId})`,
        environment: "PRODUCTION",
        awsregions: region,
        reviewowner: "admin",
        lenses: "wellarchitected",
        notes: '',
        awsCreditAccount:awsCreditAccountId,
        awsTechnicalReviewAccount,
        workloadType: "IaC",
      },
      context: {
        apiName: "well_architected",
      },
    });
    return { workloadcreation }
  };
  return { createIacWorkload };
};

export { useCreateIacWorkload };