import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import {
  GetUrlDocument,
  useSubmitQuestionnaireAnswerMutation,
} from "../../../generated";
import useSnackBars from "../../../hooks/useSnackbar";
import { IconButton, Switch } from "@mui/material";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useLazyQuery } from "@apollo/react-hooks";
import Loader from "../../common/Loader";
import { debounce } from "lodash";  // Make sure lodash is installed and imported
import useNewrelic from "../../../hooks/useNewrelic";

interface QuestionProps {
  arn: string;
  createdWorkloads: any;
  index: number;
  row: any;
  updateRow: (index: number, row: any, workloadid: string) => void;
  updateAnswer: (row: any,answers: string[]) => void;
  processAnswer: (item: any) => void;
  switchBehavior: string;
  questionnaireAnswer: any;
  process: string;
  questionnaireSpinner: any;
}

export default function Question({
  arn,
  createdWorkloads,
  index,
  row,
  updateRow,
  updateAnswer,
  processAnswer,
  switchBehavior,
  questionnaireAnswer,
  process,
  questionnaireSpinner
}: QuestionProps) {
  const { setAlerts } = useSnackBars();
  const { noticeError } = useNewrelic();
  const [activeIcon, setActiveIcon] = useState(false);
  const [disabledIcon, setDisabledIcon] = useState(false);
  const [processDocName,setProcessDocName] = useState("");
  const [disabled, setDisabled] = useState<boolean[]>(
    new Array(Object.keys(createdWorkloads).length).fill(false)
  );
  const [answer, setAnswer] = useState<string[]>(
    new Array(Object.keys(createdWorkloads).length).fill("")
  );
  const [status, setStatus] = useState<string[]>(
    new Array(Object.keys(createdWorkloads).length).fill("INITIAL")
  );

  const [submitQuestionnaireAnswerMutation] =
    useSubmitQuestionnaireAnswerMutation({
      context: {
        apiName: "well_architected",
      },
      notifyOnNetworkStatusChange: true,
  });

  const onDownloadClick = (url:string, filename:string) => {
    const element = document.createElement("a");
    element.href = url;
    element.download = filename;
    element.target = "_blank";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    setDisabledIcon(false);
  }

  const [ getRoleTemplateURL ] = useLazyQuery(GetUrlDocument,{
    onCompleted: (RoleTemplateURLData) => {
      onDownloadClick(RoleTemplateURLData?.getTemplateS3URL, processDocName);
    }
  });

  useEffect(() => {
    // Reset disabled state if switching from "All" to "Independent" or vice versa
    if (switchBehavior === "Independent" || switchBehavior === "All") {
      setDisabled(new Array(Object.keys(createdWorkloads).length).fill(false));
    }
  }, [switchBehavior, createdWorkloads]);

  useEffect(() => {
    // If switch behavior is "All", synchronize all switches
    if (
      switchBehavior === "All" && 
      answer.every(val => val === "yes") && 
      process !== "UPDATE"
    ) {
      const newDisabled = new Array(Object.keys(createdWorkloads).length).fill(true);
      setDisabled(newDisabled);
    } 
    updateAnswer(row, answer);
  }, [answer, switchBehavior, createdWorkloads]);

  useEffect(() => {
    if ((index + 1) === 7 || (index + 1) === 12 || (index + 1) === 22 || (index + 1) === 29) {
      setActiveIcon(true);
    } else {
      setActiveIcon(false);
    }
  }, [index]);

  useEffect(() => {
    if(questionnaireAnswer[row.id]){
      setAnswer([questionnaireAnswer[row.id]]);
    }    
  }, [questionnaireSpinner])

  const requestQueue = new Map<number, (() => Promise<void>)[]>();

  // Debounce the submitAnswer function so it only triggers once after rapid clicks
  const debouncedSubmitAnswer = debounce((submitAnswer: () => void) => {
    processAnswer(submitAnswer);
  }, 500);


  const handleQuestionAnswer = (index: number, workloadIndex: number, value: string) => {
    const newDisabled = [...disabled];
    newDisabled[workloadIndex] = true;
    setDisabled(newDisabled);

    const newAnswer = [...answer];
    newAnswer[workloadIndex] = value;
    setAnswer(newAnswer);

    const newStatus = [...status];
    newStatus[workloadIndex] = "IN_PROGRESS";
    setStatus(newStatus);

    //Need to update questionnaireAnswer with the new value otherwise answer will remain checked
    if (process === "UPDATE") {
      questionnaireAnswer[row["id"]] = value;      
    }

    const submitAnswer = async (retryCount = 0) => {
      try {
        await Promise.all(Object.entries(createdWorkloads).map(([_, wid], i) => {
          if (switchBehavior === "All" || i === workloadIndex) {
            return submitQuestionnaireAnswerMutation({
              variables: {
                arn,
                workLoadId: typeof wid === "string" ? wid: '',
                questionId: row["id"],
                questionAnswer: value,
                lensAlias: "wellarchitected",
              },
              context: {
                apiName: "well_architected",
              },
              notifyOnNetworkStatusChange: true,
            }).then((result: any) => {
              const newRow = { ...row };
              newRow["status"] = value;
              newRow["updateStatus"] = "IN_PROGRESS";
              if (result?.data?.submitQuestionnaireAnswer?.status === "COMPLETE") {
                newRow["updateStatus"] = "COMPLETE";
                newRow["reducedHri"] = parseInt(
                    result?.data?.submitQuestionnaireAnswer?.result
                );
              } else {
                newRow["updateStatus"] = "INITIAL";
                newRow["status"] = "";
                newRow["reducedHri"] = 0;
                setAlerts([
                    {
                        severity: "error",
                        msg: `Question ${newRow["id"]} Update failed.`,
                    },
                ]);
                const newAnswer = [...answer];
                newAnswer[workloadIndex] = "";
                setAnswer(newAnswer);
              }
              updateRow(index, newRow, typeof wid === "string" ? wid: '');

              const newDisabled = [...disabled];
              newDisabled[workloadIndex] = false;
              setDisabled(newDisabled);

              const newStatus = [...status];
              newStatus[workloadIndex] = "INITIAL";
              setStatus(newStatus);
              return true;
            });
          } else {
            return Promise.resolve();
          }
        }));
      } catch (error) {
        if (retryCount < 3) {
            setTimeout(() => submitAnswer(retryCount + 1), Math.pow(2, retryCount) * 1000);
        } else {
            noticeError(error, "Question");
        }
      }
    };

    // Queue processing with debounce
    const processQueue = async () => {
      const queue = requestQueue.get(workloadIndex);
      if (queue?.length) {
          const nextRequest = queue.shift();
          if (nextRequest) {
              await nextRequest();
              if (queue.length) {
                  setTimeout(() => processQueue(), 500);
              }
          }
      }
    };

    // Add the debounced submit request to the queue
    if (!requestQueue.has(workloadIndex)) {
        requestQueue.set(workloadIndex, []);
    }
    requestQueue.get(workloadIndex)?.push(async () => debouncedSubmitAnswer(() => submitAnswer()));

    // Start processing queue for this workloadIndex if it's not already active
    if (requestQueue.get(workloadIndex)?.length === 1) {
        processQueue();
    }

    // If switch behavior is "All", synchronize all switches after handling the current switch
    if (switchBehavior === "All" && value === "yes") {
      const newAnswer = new Array(Object.keys(createdWorkloads).length).fill("yes");
      setAnswer(newAnswer);
    } else if (switchBehavior === "All" && value === "no") {
      const newAnswer = new Array(Object.keys(createdWorkloads).length).fill("no");
      setAnswer(newAnswer);
    }
  };

  const download = (index: number) => {
    setDisabledIcon(true);
    let templateType = "";
    if ((index+1) === 7) {
      templateType = "process-documents/AWS-Incident-Management-Process-20240410.docx";
      setProcessDocName("AWS-Incident-Management-Process-20240410.docx");
    } else if ((index+1) === 12) {
      templateType = "process-documents/AWS-Information-Security-Policy-20240410.docx";
      setProcessDocName("AWS-Information-Security-Policy-20240410.docx");
    } else if ((index+1) === 22){
      templateType = "process-documents/Cloud-Disaster-Recovery-Drill-Process-20240424.docx";
      setProcessDocName("Cloud-Disaster-Recovery-Drill-Process-20240424.docx");
    } else if ((index+1) === 29){
      templateType = "process-documents/AWS-Decommissioning-Process-20240410.docx";
      setProcessDocName("AWS-Decommissioning-Process-20240410.docx");
    }
    getRoleTemplateURL({
      variables:{
          template: templateType, // value for 'template'
      }
    });

    // Toggle the switch for the current row
    const newValue = answer[0] === "yes" ? "no" : "yes";
    if (newValue !== "no"){
      handleQuestionAnswer(index, 0, newValue);
    }
  };

  return (
    <TableRow
      key={row.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {index + 1}
      </TableCell>
      <TableCell component="th" scope="row" width={"75%"}>
        {row.description}
        { activeIcon ?
            <IconButton onClick={() => download(index)} disabled={disabledIcon} type="submit" color="secondary">
              <CloudDownloadOutlinedIcon />
            </IconButton>
          : null
        }
      </TableCell>
      {
        Object.entries(createdWorkloads).map((_, workloadIndex) => (
          <>
          { process === "UPDATE" ?
            <>
            { questionnaireSpinner === true ?
              <TableCell align="center">
                <Switch
                  color="success"
                  disabled={
                    disabled[workloadIndex] || status[workloadIndex] === "IN_PROGRESS"
                  }
                  onClick={() => {
                    const newValue = answer[workloadIndex] === "yes" ? "no" : "yes";
                    handleQuestionAnswer(index, workloadIndex, newValue);
                  }}
                  checked={answer[workloadIndex] === "yes" || questionnaireAnswer[row.id] === "yes"}
                />
              </TableCell>
            : 
              <TableCell align="center">
                <Loader></Loader>
              </TableCell>
            }
            </>
          :
            <TableCell align="center">
              <Switch
                color="success"
                disabled={disabled[workloadIndex] || status[workloadIndex] === "IN_PROGRESS"}
                onClick={() => {
                  const newValue = answer[workloadIndex] === "yes" ? "no" : "yes";
                  handleQuestionAnswer(index, workloadIndex, newValue);
                }}
                checked={answer[workloadIndex] === "yes"}
              />
            </TableCell>
          }
          </>
        ))
      }
      <TableCell width={"5%"}></TableCell>
    </TableRow>
  );
}
