import { FC, useCallback, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Grid,
  Typography,
  Alert,
  Select,
  MenuItem,
  TextField,
  FormControl,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Box,
  SelectChangeEvent,
  Tooltip,
  IconButton,
} from "@mui/material";
import * as yup from "yup";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Row } from "@tanstack/react-table";
import {
  useGetMultiCloudwatchStateQuery,
  useStartMultiCloudwatchMutation,
} from "../../../generated";
import useAccount from "../../../hooks/useAccount";
import InfoIcon from "@mui/icons-material/Info";
import Control from "../../../models/Control";
import { useFormik } from "formik";
import Loader from "../Loader";
import { LoadingButton } from "@mui/lab";
import CloudwatchSwitches from "./CloudwatchSwitches";
import Progress from "./Progress";
import useGetRemediationProgress from "../../../hooks/useGetRemediationProgress";

const validationSchema = yup.object({
  cloudtrailArn: yup.string().required("CloudTrail Arn is required"),
  loggroupArn: yup.string().required("Logging Group Arn is required"),
  snsTopicArn: yup.string().required("SNS topic Arn is required"),
  snsSubscriptionEmail: yup.string().email("Invalid email address"),
  cloudwatchControls: yup
    .array(yup.string())
    .required()
    .min(1, "At least one cloudwatch control is required"),
  cloudtrail1Finding: yup.string(),
  cloudtrail2Finding: yup.string(),
  cloudtrail3Finding: yup.string(),
  cloudtrail4Finding: yup.string(),
  cloudtrail5Finding: yup.string(),
});

interface FindingControl {
  controlName: string;
  findingId: string;
  status: string;
}

interface FindingInfo {
  controlDetails: Array<FindingControl>;
}

interface Trail {
  trailArn: string;
  name: string;
  cloudwatchLogGroupArn: string;
  findingInfo: FindingInfo;
}

interface Topic {
  topicArn: string;
  topicName: string;
}
interface MultiInputCloudwatchRemediateDialogProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: (status: boolean) => void;
  row: Row<Control>;
}

interface FormData {
  trails: Array<Trail>;
  topics: Array<Topic>;
}

const MultiInputCloudwatchRemediateDialog: FC<
  MultiInputCloudwatchRemediateDialogProps
> = ({ open, handleClose, handleConfirm }) => {
  const { arn, defaultregion } = useAccount();
  const [remediating, setRemediating] = useState(false);
  const [error, setError] = useState("");
  const { remediationProgress, remediationMessage, setExecutionArn } =
    useGetRemediationProgress({
      arn,
    });
  const [formData, setFormData] = useState<FormData>({
    trails: [],
    topics: [],
  });
  const { data, loading, refetch } = useGetMultiCloudwatchStateQuery({
    variables: {
      arn,
    },
    context: {
      apiName: "automation_approval",
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const [startMultiCloudwatchMutation] = useStartMultiCloudwatchMutation();

  const formik = useFormik({
    initialValues: {
      cloudtrailArn: "",
      loggroupArn: "",
      snsTopicArn: "",
      snsSubscriptionEmail: "",
      cloudwatchControls: [],
      cloudtrail1Finding: "no_finding",
      cloudtrail2Finding: "no_finding",
      cloudtrail3Finding: "no_finding",
      cloudtrail4Finding: "no_finding",
      cloudtrail5Finding: "no_finding",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (formValues) => {
      formik.setSubmitting(true);
      setRemediating(true);
      const values = {
        ...formValues,
      };
      const awsAccountRef = arn.split(":")[4];
      if (values.cloudtrailArn === "create_new_cloudtrail") {
        if (formData.trails.length === 1) {
          formData.trails[0].findingInfo.controlDetails?.forEach(
            (control: FindingControl) => {
              if (control.controlName === "CloudTrail.1") {
                values.cloudtrail1Finding = control.findingId;
              }
            }
          );
        } else {
          formData.trails.forEach((trail: Trail) => {
            if (trail?.name && trail?.name.includes(awsAccountRef)) {
              formik.setErrors({
                cloudtrailArn:
                  "A Cloudtrail already exists for this account. Please select the existing Cloudtrail",
              });
            }
          });
          formik.setSubmitting(false);
          return;
        }
      } else {
        const trail = formData.trails.find(
          (trail: Trail) => trail.trailArn === values.cloudtrailArn
        );
        if (!trail) {
          formik.setFieldError(
            "cloudtrailArn",
            "Selected Cloudtrail is not available"
          );
          formik.setSubmitting(false);
          return;
        }
        if (
          values.loggroupArn !== "use_sixpillars_loggroup" &&
          !trail.cloudwatchLogGroupArn.includes(awsAccountRef)
        ) {
          const logGroupAwsAccountRef =
            trail.cloudwatchLogGroupArn.split(":")[4];
          formik.setFieldError(
            "cloudtrailArn",
            `Selected Multi-region Cloudtrail's log group is not available in the A+ deployed AWS account(${awsAccountRef}). Please deploy A+ in the account where the Cloudwatch log group is available (${logGroupAwsAccountRef}).`
          );
          formik.setSubmitting(false);
          return;
        }

        if (
          values.loggroupArn !== "use_sixpillars_loggroup" &&
          !trail.cloudwatchLogGroupArn.includes(defaultregion)
        ) {
          const logGroupAwsAccountRef =
            trail.cloudwatchLogGroupArn.split(":")[4];
          formik.setFieldError(
            "cloudtrailArn",
            `Selected Multi-region Cloudtrail's log group is not available in the A+ deployed AWS account(${awsAccountRef}) - ${defaultregion}. Please deploy A+ in the account where the Cloudwatch log group is available (${logGroupAwsAccountRef}).`
          );
          formik.setSubmitting(false);
          return;
        }
        trail.findingInfo.controlDetails.forEach((control: FindingControl) => {
          if (
            control.controlName === "CloudTrail.1" &&
            values.cloudtrailArn === "create_new_cloudtrail"
          ) {
            values.cloudtrail1Finding = control.findingId;
          }

          if (control.status === "FAILED") {
            if (control.controlName === "CloudTrail.2") {
              values.cloudtrail2Finding = control.findingId;
            }

            if (control.controlName === "CloudTrail.4") {
              values.cloudtrail4Finding = control.findingId;
            }
          }
        });
      }
      startMultiCloudwatchMutation({
        variables: {
          arn,
          input: {
            cloudtrailArn: values.cloudtrailArn,
            loggroupArn: values.loggroupArn,
            snsTopicArn: values.snsTopicArn,
            snsSubscriptionEmail: values.snsSubscriptionEmail,
            cloudwatchControls: values.cloudwatchControls,
            cloudtrail1Finding: values.cloudtrail1Finding,
            cloudtrail2Finding: values.cloudtrail2Finding,
            cloudtrail3Finding: values.cloudtrail3Finding,
            cloudtrail4Finding: values.cloudtrail4Finding,
            cloudtrail5Finding: values.cloudtrail5Finding,
          },
        },
        context: {
          apiName: "automation_approval",
        },
        notifyOnNetworkStatusChange: true,
      }).then((res) => {
        formik.setSubmitting(false);

        if (res.data?.startMultiCloudwatch?.executionArn) {
          setExecutionArn(res.data?.startMultiCloudwatch?.executionArn);
        } else {
          //handleConfirm(false);
          setError("Failed to start remediation");
        }
      });
    },
  });

  const handleSNSTopicChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      formik.setFieldValue("snsTopicArn", e.target.value);
    },
    [formik]
  );

  const handleOnCloudtrailChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      formik.setFieldValue("cloudtrailArn", e.target.value);
      if (e.target.value === "create_new_cloudtrail") {
        // open create cloudtrail dialog
        formik.setFieldValue("loggroupArn", "use_sixpillars_loggroup");
      } else {
        const trail = formData.trails.find(
          (trail: Trail) => trail.trailArn === e.target.value
        );
        if (trail?.cloudwatchLogGroupArn) {
          formik.setFieldValue("loggroupArn", trail?.cloudwatchLogGroupArn);
        } else {
          formik.setFieldValue("loggroupArn", "use_sixpillars_loggroup");
        }
      }
    },
    [formik, formData.trails]
  );

  const handleRefetch = useCallback(() => {
    refetch();
    setRemediating(false);
    setExecutionArn("");
  }, [refetch]);

  useEffect(() => {
    formik.setSubmitting(false);
    formik.setErrors({});
    if (!loading && data?.getMultiCloudwatchState?.cloudTrails) {
      const trails = JSON.parse(data?.getMultiCloudwatchState?.cloudTrails);
      const topics = JSON.parse(data?.getMultiCloudwatchState?.snsTopics);
      setFormData({
        trails,
        topics,
      });
    }
  }, [data, loading]);

  useEffect(() => {
    if (remediationProgress === 100 && remediating) {
      handleConfirm(true);
      setRemediating(false);
    }
  }, [remediationProgress, handleConfirm]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"md"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Multi Cloudwatch Remediations for AWS Account : {arn.split(":")[4]}{" "}
        {defaultregion}
      </DialogTitle>
      {!loading ? (
        <>
          <Box sx={{ display: "flex", pr: 2.5, justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleRefetch}
            >
              Refresh
            </Button>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Grid container sx={{ m: 1 }}>
                <Grid item md={6}>
                  <Typography color="primary">
                    Email address{" "}
                    <Tooltip title="You will receive an SNS topic subscription confirmation email. Please click the link in the email to confirm your subscription.">
                      <IconButton>
                        <InfoIcon fontSize="small" color="info" />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      id="snsSubscriptionEmail"
                      name="snsSubscriptionEmail"
                      label="SNS Subscription Email"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={formik.values.snsSubscriptionEmail}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.snsSubscriptionEmail &&
                        Boolean(formik.errors.snsSubscriptionEmail)
                      }
                      helperText={
                        formik.touched.snsSubscriptionEmail &&
                        formik.errors.snsSubscriptionEmail
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container sx={{ m: 1 }}>
                <Grid item md={6}>
                  <Typography color="primary">
                    Multi region Cloudtrail{" "}
                    <Tooltip title="Please select a multi-region CloudTrail to enable CloudWatch alarms. If you do not have a multi-region CloudTrail, you can create a new one. If your organization is set up with multiple accounts, select the CloudTrail deployed in the account where A+ is implemented.">
                      <IconButton>
                        <InfoIcon fontSize="small" color="info" />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth size="small">
                    <Select
                      name="cloudtrailArn"
                      value={formik.values.cloudtrailArn}
                      fullWidth
                      onChange={handleOnCloudtrailChange}
                    >
                      <MenuItem key="Select Cloudtrail" value={""}>
                        Select Cloudtrail
                      </MenuItem>
                      {formData.trails.map((trail: Trail) => (
                        <MenuItem
                          key={trail.trailArn}
                          value={trail.trailArn}
                          sx={{ textOverflow: "ellipsis" }}
                        >
                          {trail.name}
                        </MenuItem>
                      ))}
                      {formData.trails.length === 1 &&
                        !formData.trails[0]?.trailArn && (
                          <MenuItem
                            key={"create_new_cloudtrail"}
                            value={"create_new_cloudtrail"}
                          >
                            Create New Cloudtrail
                          </MenuItem>
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container sx={{ m: 1 }}>
                <Grid item md={6}>
                  <Typography color="primary">
                    Cloudwatch log group
                    <Tooltip title="This log group will be used to create CloudWatch metrics and will be added to the selected CloudTrail.">
                      <IconButton>
                        <InfoIcon fontSize="small" color="info" />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth size="small">
                    <TextField
                      id="loggroupArn"
                      name="loggroupArn"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={formik.values.loggroupArn}
                      disabled
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container sx={{ m: 1 }}>
                <Grid item md={6}>
                  <Typography color="primary">
                    SNS Topic ARN
                    <Tooltip title="CloudWatch 1-14 requires an SNS topic to deliver CloudWatch alarms to users.">
                      <IconButton>
                        <InfoIcon fontSize="small" color="info" />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth size="small">
                    <Select
                      id="snsTopicArn"
                      name="snsTopicArn"
                      value={formik.values.snsTopicArn}
                      onChange={handleSNSTopicChange}
                    >
                      <MenuItem key="Select SNS Topic" value={""}>
                        Select SNS Topic
                      </MenuItem>
                      {formData.topics.map((topic: Topic) => {
                        return (
                          <MenuItem key={topic.topicArn} value={topic.topicArn}>
                            {topic.topicName}
                          </MenuItem>
                        );
                      })}
                      {
                        <MenuItem
                          key="create_new_sns_topic"
                          value="use_sixpillars_topic"
                        >
                          Use SixPillars SNS Topic
                        </MenuItem>
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={4}>
                      Please select the Cloudwatch controls you wish to enable
                    </TableCell>
                  </TableRow>
                </TableHead>
                <CloudwatchSwitches
                  formikCloudwatchControls={formik.values.cloudwatchControls}
                  setFieldValue={formik.setFieldValue}
                />
              </Table>
            </DialogContent>
            {Object.values(formik.errors).map((error) => {
              return (
                <Alert key={error.toString()} severity="error">
                  {error}
                </Alert>
              );
            })}
            {error !== "" && (
              <Alert
                severity="error"
                sx={{ display: error ? "block" : "none" }}
              >
                {error}
              </Alert>
            )}
            {(remediating || remediationProgress > 0) && (
              <Progress
                progress={remediationProgress}
                message={remediationMessage}
              />
            )}
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <LoadingButton
                variant="contained"
                color="secondary"
                disabled={remediating}
                loading={remediating}
                type="submit"
              >
                Start Workflow
              </LoadingButton>
            </DialogActions>
          </form>
        </>
      ) : (
        <DialogContent>
          <Typography>
            Please wait. We are analyzing your environment for optimal
            cloudwatch.1-14 remediation.
          </Typography>
          <Loader />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default MultiInputCloudwatchRemediateDialog;
