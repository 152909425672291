import { FC, useEffect, useState } from "react";
import { Box, CircularProgress, Collapse, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import MilestoneNumber from "../common/MilestoneNumber";
import DeleteWorkload from "../common/DeleteWorkload";
import HighRiskNumbers from "../common/HighRiskNumbers";
import UpdateButton from "../common/UpdateButton";
import DownloadReports from "../common/DownloadReports";
import StyledTableCell from "../../common/StyledTableCell";
import { useGetWorkloadQuery, useListMilestonesQuery } from "../../../generated";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import useAccount from "../../../hooks/useAccount";
import ConnectedAccount from "../../../models/ConnectedAccount";

interface Workload {
  WorkloadId: string;
  WorkloadArn: string;
  WorkloadName: string;
  Owner: string;
  UpdatedAt: string;
  Lenses: [string];
  RiskCounts: {
    UNANSWERED: number,
    HIGH: number,
    MEDIUM: number,
    NONE: number,
    NOT_APPLICABLE: number,
  };
  ImprovementStatus: string;
  AwsCreditAccount: string;
  AwsWafrAccount: string;
  AwsTechnicalReviewAccount: string[];
}

interface ListWorkLoadItemProps {
  arn: string;
  workload: Workload;
  updateWorkLoad: (
    workLoadId: string,
    workLoadName: string,
    highRiskCount: number,
    initialHighRiskCount: number,
    technicalReviewArn: string,
    connectedAccounts: Array<ConnectedAccount>
  ) => void;
}

const ListWorkLoadItem: FC<ListWorkLoadItemProps> = ({
  arn,
  workload,
  updateWorkLoad,
}: ListWorkLoadItemProps) => {
  const { accounts, connectedAccounts } = useAccount();
  const [connectedAccountsDropDown, setConnectedAccountsDropDown] =
    useState(connectedAccounts);
  const [accountIdRef, setAccountIdRef] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [initialHighRiskCount, setInitialHighRiskCount] = useState(0);
  const [technicalReviewArn, setTechnicalReviewArn] = useState("");
  const [accountAssessed, setAccountAssessed] = useState("");
  
  const { data, loading, refetch } = useListMilestonesQuery({
    variables: {
      arn,
      workloadid: workload.WorkloadId,
    },
    context: {
      apiName: "well_architected",
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: getWorkloadData, loading: getWorkloadLoading } = useGetWorkloadQuery({
    variables: {
      arn,
      WorkloadId: workload.WorkloadId,// value for 'WorkloadId'
    },
    context: {
      apiName: "well_architected",
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const firstHighRiskCount: number = Math.max(
      ...(data?.listMilestones?.MilestoneSummaries.map(
        (milestone) => milestone.RiskCounts?.HIGH || 0
      ) || [0, 0])
    );

    setInitialHighRiskCount(firstHighRiskCount);
  }, [data, loading]);

  useEffect(() => {
    refetch({arn, workloadid: workload.WorkloadId});
  }, [refetch, arn, workload]);

  const getFilteredConnectedAccounts = () => {
    return connectedAccountsDropDown
      .filter((connectedAccount: ConnectedAccount) => {
        return connectedAccount.accountIdRef === accountIdRef;
      })
      .map((connectedAccount: ConnectedAccount) => {
        return {
          accountIdRef: connectedAccount.accountIdRef,
          region: connectedAccount.region,
        };
      });
  };
  
  useEffect(() => {
    if(getWorkloadData){
      const account = accounts.find(
        (i: any) => {
          if (i.connectedAccounts.length !== 0){
            for(const x of i.connectedAccounts){
              if(x?.accountIdRef === getWorkloadData?.getWorkload?.AccountIds?.[0]){
                return i;
              }
            }
          } else if (i.arn.split(":")[4] === getWorkloadData?.getWorkload?.AccountIds?.[0]){
            return i;
          }
      });
      if(account){
        setConnectedAccountsDropDown(account.connectedAccounts);
        setAccountIdRef(getWorkloadData?.getWorkload?.AccountIds?.[0] || "");
        setAccountAssessed(`${account?.accessnickname} - ${getWorkloadData?.getWorkload?.AccountIds?.[0]}`);
        setTechnicalReviewArn(account.arn);  
      } else {
        setAccountAssessed("N/A");
        setTechnicalReviewArn("");  
      }
    }
  }, [getWorkloadData, accounts]);

  //Update getFilteredConnectedAccounts() when accountIdRef changes
  useEffect(() => {
    getFilteredConnectedAccounts();
  }, [accountIdRef]);

  //Workload Dropdown Information
  const WorkloadDetails = ({
    workload,
    getWorkloadData,
    accountAssessed,
  }: {
    workload: Workload;
    getWorkloadData: any;
    accountAssessed: string;
  }) => (
    <Box sx={{ margin: 1, display: "block" }}>
      <Typography variant="body1" align="left" gutterBottom>
        <b>AWS WAFR Account:</b> {workload.AwsWafrAccount}
      </Typography>
      <Typography variant="body1" align="left" gutterBottom>
        <b>AWS Credit Account:</b> {workload.AwsCreditAccount}
      </Typography>
      <Typography variant="body1" align="left" gutterBottom>
        <b>AWS Technical Review Account:</b> {accountAssessed}
      </Typography>
      <Typography variant="body1" align="left" gutterBottom>
        <b>Lenses:</b> Well-Architected
      </Typography>
      <Typography variant="body1" align="left" gutterBottom>
        <b>AWS Regions:</b> {getWorkloadData?.getWorkload?.AwsRegions?.[0] || "N/A"}
      </Typography>
      <Typography variant="body1" align="left" gutterBottom>
        <b>Workload Id:</b> {getWorkloadData?.getWorkload?.WorkloadId || "N/A"}
      </Typography>
    </Box>
  );

  return (
    <>
      <TableRow>
        <StyledTableCell>{workload.WorkloadName}</StyledTableCell>
        <StyledTableCell>{new Date(workload.UpdatedAt).toLocaleString("en-GB")}</StyledTableCell>
        <StyledTableCell align="center">
          <MilestoneNumber
            arn={arn}
            workloadid={workload.WorkloadId}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <HighRiskNumbers highrisknumber={workload.RiskCounts.HIGH} loading={loading} />
        </StyledTableCell>
        <StyledTableCell align="center">
          {getWorkloadLoading ? 
            <CircularProgress size={15} />
            :
            <UpdateButton
              arn={arn}
              workloadid={workload.WorkloadId}
              milestonename={workload.WorkloadName}
              updateWorkLoad={() => {
                updateWorkLoad(
                  workload.WorkloadId,
                  workload.WorkloadName,
                  workload.RiskCounts.HIGH,
                  initialHighRiskCount,
                  technicalReviewArn,
                  getFilteredConnectedAccounts()
                );
              }}
            />
          }
        </StyledTableCell>
        <StyledTableCell align="center">
          <DownloadReports
            arn={arn}
            workloadname={workload.WorkloadName}
            workloadid={workload.WorkloadId}
            workloadlens={"wellarchitected"}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <DeleteWorkload
            arn={arn}
            workloadid={workload.WorkloadId}
            workloadname={workload.WorkloadName}
            workloadType="CloudInfra"
          />
        </StyledTableCell>
        <StyledTableCell>
          <IconButton color="secondary" onClick={() => setOpen(!open)}>
            {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <WorkloadDetails
              workload={workload}
              getWorkloadData={getWorkloadData}
              accountAssessed={accountAssessed}
            />
          </Collapse>
        </TableCell>
      </TableRow>
  </>
  );
};

export default ListWorkLoadItem;
