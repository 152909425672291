import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SecurityIcon from "@mui/icons-material/Security";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import RunCircleIcon from "@mui/icons-material/RunCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import Tooltip from "@mui/material/Tooltip";
import ConstructionIcon from "@mui/icons-material/Construction";

interface PillarIconProps {
  pillar: string;
}

const PillarIcon = ({ pillar }: PillarIconProps) => {
  const iconMap: any = {
    OPS: (
      <Tooltip title="Operational Excellence">
        <CheckCircleOutlineIcon sx={{ color: "#0e1956" }} />
      </Tooltip>
    ),
    SEC: (
      <Tooltip title="Security">
        <SecurityIcon sx={{ color: "#e90000" }} />
      </Tooltip>
    ),
    PERF: (
      <Tooltip title="Performance Efficiency">
        <RunCircleIcon sx={{ color: "#0e1956" }} />
      </Tooltip>
    ),
    COST: (
      <Tooltip title="Cost Optimisation">
        <MonetizationOnIcon sx={{ color: "black" }} />
      </Tooltip>
    ),
    REL: (
      <Tooltip title="Reliability">
        <ArrowCircleUpIcon sx={{ color: "#ff6600" }} />
      </Tooltip>
    ),
    SUS: (
      <Tooltip title="Sustainability">
        <EnergySavingsLeafIcon sx={{ color: "#008a02" }} />
      </Tooltip>
    ),
    PD: (
      <Tooltip title="Possibly Disruptive">
        <ConstructionIcon sx={{ color: "#e90000" }} />
      </Tooltip>
    ),
    ND: (
      <Tooltip title="Non-Disruptive">
        <ConstructionIcon sx={{ color: "#008a02" }} />
      </Tooltip>
    ),
  };

  return iconMap[pillar] || "";
};

export default PillarIcon;
