import { useState } from 'react';
import Stack from '@mui/material/Stack';
import { ListWorkloadsDocument, useDeleteWorkloadMutation } from "../../../generated";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import useSnackBars from '../../../hooks/useSnackbar';

interface DeleteWorkloadProps {
  arn: string;
  workloadid: string;
  workloadname: string;
  workloadType: string;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function DeleteWorkload({arn, workloadid, workloadname, workloadType}: DeleteWorkloadProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [disable, setDisable] = useState(false);
  const { setAlerts } = useSnackBars();
  
  const [deleteWorkloadMutation] = useDeleteWorkloadMutation({
    variables: {
      arn,
      workloadid
    },
    context: {
      apiName: "well_architected"
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      handleClose();
      setDisable(false);
    },
    refetchQueries: [{
      query: ListWorkloadsDocument,
      context: {
        apiName: "well_architected"
      },
      variables: {
        arn,
        workloadType,
      },
    }]
  });

  function Buttonclick(){
    setDisable(true);
    deleteWorkloadMutation().then(() => {
      setAlerts([
        {
          severity: "success",
          msg: "Workload successfully deleted",
        },
      ]);
    })
    .catch((e) => {
      setAlerts([
        {
          severity: "error",
          msg: `Workload deletion failed - ${e}`,
        },
      ]);
    });
  }

  return (
    <Stack direction="row">
      <IconButton onClick={handleOpen} color="secondary"><DeleteOutlineIcon /></IconButton>
      <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xs md={10}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete Workload
              </Typography>
            </Grid>
            <Grid item xs md={2}>                            
              <Button onClick={() => handleClose()}><CloseIcon /></Button>
            </Grid>
            <Grid item xs md={12}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete &apos;{workloadname}&apos;?
              </Typography>
            </Grid>
            <Grid item xs md={4} sx={{ mt: 2}}></Grid>
            <Grid item xs md={8} sx={{ mt: 2}}>
              <Stack
                sx={{ pt: 4 }}
                direction="row"
                spacing={2}
              >
                <Button 
                  disabled={disable} 
                  onClick={() => Buttonclick()} 
                  type="submit" 
                  variant="outlined" 
                  sx={{
                      width: 157,
                      height: 40,
                      "&:hover": {
                          color: "primary.main"
                      }
                  }}
                >
                  Yes
                </Button>
                <Button 
                  disabled={disable} 
                  onClick={() => handleClose()} 
                  type="submit" 
                  variant="contained"
                  sx={{
                      width:  157,
                      height: 40,
                      bgcolor: "secondary.main",
                      "&:hover": {
                          bgcolor:"secondary.main",
                          color: "secondary.contrastText"
                      }
                  }}
                >
                  No
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>                
    </Stack>
  );
}