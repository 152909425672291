import { FC, useState } from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { CreateMilestoneDocument } from "../../../generated";
import { useMutation } from "@apollo/react-hooks";
import OnResult from "../../subscriptions/OnResult";

interface UpdateButtonProps {
  arn: string;
  workloadid: string;
  milestonename: string;
  updateWorkLoad: () => void;
}

const UpdateButton: FC<UpdateButtonProps> = ({
  arn,
  workloadid,
  milestonename,
  updateWorkLoad,
}: UpdateButtonProps) => {
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [stateMachineId, setStateMachineId] = useState(null);
  const [subscriptionResult, setSubscriptionResult] = useState(null);

  const [createMilestoneMutation] = useMutation(CreateMilestoneDocument, {
    onCompleted: () => {
      setActiveSpinner(false);
    },
  });

  function CreateMilestone() {
    createMilestoneMutation({
      variables: {
        arn,
        workloadid,
        milestonename,
      },
      context: {
        apiName: "well_architected",
      },
      notifyOnNetworkStatusChange: true,
    });
  }

  if (subscriptionResult !== null) {
    CreateMilestone();
    setSubscriptionResult(null);
  }

  return (
    <>
      <IconButton
        onClick={() => {
          updateWorkLoad();
        }}
        type="submit"
        color="secondary"
      >
        {activeSpinner ? <CircularProgress /> : <AutorenewIcon />}
        {stateMachineId !== null ? (
          <OnResult
            statemachineid={stateMachineId}
            setSubscriptionResult={setSubscriptionResult}
            setStateMachineId={setStateMachineId}
          />
        ) : null}
      </IconButton>
    </>
  );
};

export default UpdateButton;
