import { OnResultDocument, useUpdateAnswerMutation } from "../generated";
import { useApolloClient } from "@apollo/client";
import ConnectedAccount from "../models/ConnectedAccount";
import useNewrelic from "./useNewrelic";

const useUpdateWorkload = () => {
	const { noticeError } = useNewrelic();
	const [updateAnswerMutation] = useUpdateAnswerMutation();
	const client = useApolloClient();

	const updateWorkload = async (
		wafrArn: string,
		workloadid: string,
		target_arn: string,
		connectedAccounts: Array<ConnectedAccount>,
	) => {
		const workloadupdate = await updateAnswerMutation({
			variables: {
				arn:wafrArn, // value for 'awsWafrAccount'
				targetAccountArn: target_arn, // value for 'targetAccountArn'
				workloadid, // value for 'workloadid'
				lens: "wellarchitected", // value for 'lens'
				connectedAccounts, // value for 'connectedAccounts'
			},
			context: {
				apiName: "well_architected",
			},
			notifyOnNetworkStatusChange: true,
		}).then(async ({data}:any) =>{
			let substatus = false;
			const subscriptionPromise = await new Promise((resolve, reject) => {
				client
				?.subscribe({
					query: OnResultDocument,
					variables: {
						id: data?.updateAnswer?.id,
					},
				})
				.subscribe({
					next: () => {
						substatus = true;
						resolve(
							true
						);
					},
					error: () => {
						substatus = false;
						reject(
							new Error("Error in Update Workload!"),
						);  
					},
				});
			}).then(() =>{
				return substatus;
			}).catch(error => {
				noticeError(error.message, "useUpdateWorkload");
			});
			return subscriptionPromise;
		});
		return {workloadupdate};
	};

	return {updateWorkload};
};

export { useUpdateWorkload };