import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import {
  GetLensReviewDocument,
  GetQuestionnaireTableDocument,
  useGetQuestionnaireQuery,
  useUpdateQuestionnaireTableMutation,
} from "../../../generated";
import { Button, Typography } from "@mui/material";
import Loader from "../../common/Loader";
import { useQueue } from "../../../hooks/useQueue";
import { useLazyQuery } from "@apollo/client";
import AnswerQuestionnaireIacModal from "./AnswerQuestionnaireIacModal";
import QuestionIac from "./QuestionIac";
import { useSubmitIacEmail } from "../../../hooks/useSubmitIacEmail";

interface WorkloadsStageDictionary {
  [key: string]: string;
}

interface EmailSubmittedDictionary {
  [key: string]: boolean;
}

interface RiskCountDictionary {
  [key: string]: number;
}

interface RiskCount {
  hriCount: number;
  initialHriCount: number;
}

interface AnswerQuestionnaireIacProps {
  organisation: any;
  wafrArn: string;
  createdWorkloads: any;
  riskCounts: RiskCount;
  halfStageHriCount: number;
  riskCountsDict: any;
  firstRiskCountsDict: any;
  setRiskCountsDict: any;
  stateStage: any;
  setStateStage: any;
  changeTab: (index: number) => void;
  setUnlockSteps: React.Dispatch<React.SetStateAction<{
    step2: boolean; step3: boolean; step4: boolean 
  }>>;
  process: string;
}

function createData(
  id: string,
  description: string,
  status: string,
  updateStatus: string,
  reducedHri: number
) {
  return { id, description, status, updateStatus, reducedHri };
}

function createAnswerData(
  id: string,
  description: string,
  status: string[],
  updateStatus: string,
  reducedHri: number
) {
  return { id, description, status, updateStatus, reducedHri };
}

type RowQuestion = {
  id: string;
  description: string;
  status: string;
  updateStatus: string;
  reducedHri: number;
};

type AnswerQuestion = {
  id: string;
  description: string;
  status: string[];
  updateStatus: string;
  reducedHri: number;
};

export default function AnswerQuestionnaireIac({
  organisation,
  wafrArn,
  createdWorkloads,
  riskCounts,
  halfStageHriCount,
  riskCountsDict,
  firstRiskCountsDict,
  setRiskCountsDict,
  stateStage,
  setStateStage,
  changeTab,
  setUnlockSteps,
  process,
}: AnswerQuestionnaireIacProps) {
  const { addToQueue } = useQueue({ wafrArn });
  const [rows, setRows] = useState<Array<RowQuestion>>([]);
  const [answer, setAnswer] = useState<Array<AnswerQuestion>>([]);
  const [emailSubmitted, setEmailSubmitted] = useState<EmailSubmittedDictionary>({});
  const [questionnaireAnswer, setQuestionnaireAnswer] = useState({});
  const [questionnaireSpinner, setQuestionnaireSpinner] = useState(false);

  const { data, loading } = useGetQuestionnaireQuery({
    variables: {
      arn: wafrArn,// value for 'arn'
    },
    context: {
      apiName: "well_architected",
    },
  });
  
  const { submitemail_mutation } = useSubmitIacEmail({ wafrArn, emailSubmitted, setEmailSubmitted });

  const [getLensReview] = useLazyQuery(GetLensReviewDocument,{
    context: {
      apiName: "well_architected",
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [updateQuestionnaireTableMutation] = useUpdateQuestionnaireTableMutation();
  
  const [ getQuestionnaireTable ] = useLazyQuery(GetQuestionnaireTableDocument,{
    onCompleted: (questionnaireData) => {
      const response = JSON.parse(questionnaireData.getQuestionnaireTable);
      setQuestionnaireAnswer(
        response
      );
      const updatedAnswer = answer.map(question => {
        if (response[question.id]) {
            // Update the status property
            return { ...question, status: [response[question.id]] };
        }
        return question;
      });
      // Update the answer state with the modified array
      setAnswer(updatedAnswer);
      // Can stop the spinner now
      setQuestionnaireSpinner(true);
    }
  });

  const addWorkloadStageKeyValuePairs = (newPairs: WorkloadsStageDictionary) => {
    setStateStage((stateStage: any) => ({
      ...stateStage,
      ...newPairs
    }));
  };

  const addRiskKeyValuePairs = (newPairs: RiskCountDictionary) => {
    setRiskCountsDict((riskCountsDict: any) => ({
      ...riskCountsDict,
      ...newPairs
    }));
  };

  const addEmailSubmittedKeyValuePairs = (newPairs: EmailSubmittedDictionary) => {
    setEmailSubmitted((emailSubmitted: any) => ({
      ...emailSubmitted,
      ...newPairs
    }));
};

  const getHriResult = async (workloadid: string) => {
    /**
     * Get Hri Result
     * @param workloadid Well-Architected Workload Id
     */
    const lensReview = await getLensReview({
      variables: {
        arn: wafrArn,
        workloadid,
        lens: "wellarchitected",
      },
    });
    let hri = 0;
    hri = lensReview?.data?.getLensReview?.PillarReviewSummaries?.map(
      (element: any) => {
        return element?.RiskCounts?.HIGH;
      }
    ).reduce((pre: number, cur: number) => {
      return pre + cur;
    }, 0);
    return hri
  };

  //Handle that locks step 3
  const handleUnlockSteps = () => {
    setUnlockSteps(prevState => ({
      ...prevState,
      step3: false, // Lock step 3
    }));
  };

  useEffect(() => {
    if (!loading && data?.getQuestionnaire) {
      handleUnlockSteps();
      setRows(
        data?.getQuestionnaire.map((q: any) => {
          return createData(q.id, q.description, "", "INITIAL", 0);
        })
      );
      setAnswer(
        data?.getQuestionnaire.map((q: any) => {
          return createAnswerData(q.id, q.description, [], "INITIAL", 0);
        })
      );
      const promises = Object.entries(createdWorkloads).map(async ([_key, value]) => {
        const hri = await Promise.resolve(getHriResult(typeof value === "string" ? value: ''));
        await getQuestionnaireTable({
          variables: {
            arn: wafrArn,
            workloadid: typeof value === "string" ? value: '',// value for 'workloadid'
          },
          context: {
            apiName: "well_architected",
          },
        });
        if (hri !== undefined) {
          addRiskKeyValuePairs({[typeof value === "string" ? value: '']:hri})
          addEmailSubmittedKeyValuePairs({[typeof value === "string" ? value: '']:false});
        }
      });
      Promise.all(promises);
    }
  }, [data, loading, createdWorkloads]);

  const processAnswer = (fn: any) => {
    addToQueue(fn);
  };
  
  if(loading){
    window.scrollTo({ top: 0, behavior: 'smooth' }) //scroll to the top of the page
  }
  
  const updateAnswer = (row: RowQuestion, answers: string[]) =>{
    const updatedAnswer = answer.map(question => {
      if (question.id === row.id) {
          // Update the status property
          return { ...question, status: answers, updateStatus: row.updateStatus };
      }
      return question;
    });
    // Update the answer state with the modified array
    setAnswer(updatedAnswer);
  }

  /**
   * Update Questionnaire Table
   * @param workloadid Well-Architected Workload Id
   * @param questionid Question Id
   * @param answer Answer Yes or No
   */
  const updateQuestionnaireTable = async (workloadid: string, questionid: string, answer: string) =>{
    await updateQuestionnaireTableMutation({
      variables: {
        arn: wafrArn,
        workloadid,// value for 'workloadid'
        questionid,// value for 'questionid'
        answer,// value for 'answer'
      },
      context: {
        apiName: "well_architected",
      },
    });
  }

  /**
   * Update Table Row
   * @param index row index number
   * @param row row question
   * @param workloadid Well-Architected Workload Id
   */
  const updateRow = async (index: number, row: RowQuestion, workloadid: string) => {
    const hri = await getHriResult(workloadid);
    if (hri !== undefined) {
      addRiskKeyValuePairs({[workloadid]:hri})
    }
    updateQuestionnaireTable(workloadid, row.id, row.status);
    if (riskCounts.hriCount <= halfStageHriCount / 2 && stateStage[workloadid] !== "HALF_STAGE") {
      addWorkloadStageKeyValuePairs({[workloadid]:"HALF_STAGE"});
      if(emailSubmitted[workloadid] === false){
        await Promise.all([
          submitemail_mutation(workloadid),
        ]);
      }
    }
  };

  /**
   * csv lines
   * @param lines csv line 
   * @returns lines
   */
  const csvLines = (lines: any) => {
    return lines
      .map((e: any) => {
        return e.join(",");
      })
      .join("\r\n");
  };

  /**
   * Export CSV
   */
  const exportCsv = () => {
    const header = ['ID', 'Description']
    Object.entries(createdWorkloads).forEach(([key, _value]) => {
      const name = key.split(`${organisation}-`).join('');
      header.push(name)
    })
    const csvFile = new Blob(
      [
        `${csvLines([
          header,
        ])}\r\n${csvLines(
          answer.map((question: any) => [
            question.id,
            JSON.stringify(question.description),
            question.status.join(","),
          ])
        )}`,
      ],
      {
        type: "text/csv",
      }
    );
    const name = `WAFR_People_Policy_and_Tools_${Date.now()}.csv`;
    const link = document.createElement("a");
    link.href = URL.createObjectURL(csvFile);

    link.setAttribute("visibility", "hidden");
    link.download = name;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }; 

  return (
    <Container maxWidth="lg" component={Paper}>
      <Grid container sx={{ p: 1 }}>
        <Typography>
          NB: You will need to answer all questions in the one sitting due to
          the way that the workload is updated. If you would like any
          assistance, please reach out to secure@6pillars.ai
        </Typography>
      </Grid>
      <Grid item xs md={4}/>
      <Grid
        container
        sx={{ pb: 2, pt: 2, pl: 2 }}
        spacing={2}
        alignItems="center"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, pt:10 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell/>
                <TableCell>Question</TableCell>
                {
                  Object.entries(createdWorkloads).map(([key, value]) => {
                    const workloadid = typeof value === "string" ? value: '';
                    const name = key.split(`${organisation}-`).join('');
                    const percentage = Math.round(
                      ((firstRiskCountsDict[workloadid]-riskCountsDict[workloadid])/firstRiskCountsDict[workloadid]) * 100
                    );
                    return(
                      <TableCell key={key} align="center">
                        {name}
                        <br/>
                        {riskCountsDict[workloadid]}/{firstRiskCountsDict[workloadid]}({percentage}%) 
                      </TableCell>
                    );
                  })
                }
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 && (
                <TableRow
                  key={1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={3}>
                    <Loader/>
                  </TableCell>
                </TableRow>
              )}
              {rows.map((row, index) => (
                <QuestionIac
                  key={index}
                  wafrArn={wafrArn}
                  createdWorkloads={createdWorkloads}
                  index={index}
                  row={row}
                  updateRow={updateRow}
                  updateAnswer={updateAnswer}
                  processAnswer={processAnswer}
                  questionnaireAnswer={questionnaireAnswer}
                  process={process}
                  questionnaireSpinner={questionnaireSpinner}
                />
              ))}
              <TableRow>
                <TableCell/>
                <TableCell/>
                {
                  Object.entries(createdWorkloads).map(([key, value]) => {
                    const workloadid = typeof value === "string" ? value: '';
                    const percentage = Math.round(
                      ((firstRiskCountsDict[workloadid]-riskCountsDict[workloadid])/firstRiskCountsDict[workloadid]) * 100
                    );
                    return(
                      <TableCell key={key} align="center">
                        {riskCountsDict[workloadid]}/{firstRiskCountsDict[workloadid]}({percentage}%) 
                      </TableCell>
                    );
                  })
                }
                <TableCell/>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", pt: 3, direction: "row" }}>
          <Grid item xs md={8}>
            <Typography sx={{ pl: 1, pr: 2 }}>
              Click to submit your Well-Architected Framework Review
            </Typography>
          </Grid>
          <Grid item xs md={2} sx={{pr: 4}}>
            <Button
              onClick={exportCsv}
              type="submit"
              variant="contained"
              sx={{
                width: 157,
                height: 40,
                bgcolor: "secondary.main",
                "&:hover": {
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                },
              }}
            >
              Download
            </Button>
          </Grid>
          <Grid item xs md={2} sx={{pl: 4}}>
            <AnswerQuestionnaireIacModal
              wafrArn={wafrArn}
              rows={rows}
              createdWorkloads={createdWorkloads}
              changeTab={changeTab}
            />
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
}
