import { useLazyQuery } from "@apollo/client";
import { 
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  Typography 
} from "@mui/material";
import { useState } from "react";
import { GetFindingsDocument } from "../../generated";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import useSnackBars from "../../hooks/useSnackbar";

interface GetFindingsProps {
  arn: string;
  organisation: string;
  accessnickname: string;
  externalid: string;
  region: string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function GetFindings({
  arn,
  organisation,
  accessnickname,
  externalid,
  region
}: GetFindingsProps) {
  const { setAlerts } = useSnackBars();
  const [getFindings] = useLazyQuery(GetFindingsDocument, {});
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);

  const handleOpen = async () => {
    setOpen(true);
    setDisable(true);
    const results = await Promise.all([
      getFindings({
        variables:{
          organisation, // value for 'organisation'
          accessnickname, // value for 'accessnickname'
          arn,// value for 'arn'
          externalid, // value for 'externalid'
          region, // value for 'region'
        },
        context: {
          apiName: "user_deploy_process",
        },
      }),
    ]);
    if(results?.[0]?.data?.getFindings.status === "COMPLETE"){
      setDisable(false);
    } else {
      setAlerts([
        {
          severity: "error",
          msg: `Error Processing Findings`,
        },
      ]);
    }
  };
  const handleClose = () => setOpen(false);

	return (
		<Stack direction="row">
			<Button
        onClick={handleOpen}
        id="automate-rollback-btn"
        type="submit"
        variant="contained"
        sx={{
          width: 157,
          height: 40,
          bgcolor: "secondary.main",
          "&:hover": {
            bgcolor: "secondary.main",
            color: "secondary.contrastText",
          },
        }}
      >
        Get Findings
			</Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Processing Findings: {arn.split(":")[4]}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Your findings are currently being pulled into. This process will take about 15 minutes.
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            disabled={disable}
            loading={disable}
            onClick={handleClose}
            type="submit"
            variant="contained"
            sx={{
              width: 157,
              height: 40,
              bgcolor: "secondary.main",
              "&:hover": {
                bgcolor: "secondary.main",
                color: "secondary.contrastText",
              },
            }}
            loadingIndicator={
              <CircularProgress color={"secondary"} size={22} />
            }
          >
            Close
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
		</Stack>
	);
}