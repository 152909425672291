import { useCreateWorkloadMutation } from "../generated";
import ConnectedAccount from "../models/ConnectedAccount";
import { useCreateMilestone } from "./useCreateMilestone";
import { useUpdateWorkload } from "./useUpdateWorkload";

const useCreateWorkload = () => {
  const [createWorkloadMutation] = useCreateWorkloadMutation();

  const { updateWorkload } = useUpdateWorkload();

  const { createMilestone } = useCreateMilestone();

  const createWorkload = async (
    wafrArn: string,
    workloadname: string,
    target_arn: string,
    connectedAccounts: Array<ConnectedAccount>,
    accountIdRef: string,
    awsCreditAccountId: string,
    wafrRegion: string,
  ) => {
    let account_assessed_id = target_arn.split(":")[4];
    if (accountIdRef !== "") {
      account_assessed_id = accountIdRef;
    }
    const workloadcreation = await createWorkloadMutation({
      variables: {
        arn:wafrArn,
        workloadname,
        description: `Mapping Security Hub with Well-Architected Tool (AWS Credit Account : ${awsCreditAccountId})`,
        environment: "PRODUCTION",
        awsregions: wafrRegion,
        reviewowner: "admin",
        lenses: "wellarchitected",
        notes: "",
        awsCreditAccount:awsCreditAccountId,
        awsTechnicalReviewAccount: account_assessed_id,
        workloadType: "CloudInfra",
      },
      context: {
        apiName: "well_architected",
      },
    });
    if (workloadcreation?.data?.createWorkload?.Status === "SUCCESS") {
      const updateanswer = await Promise.all([
        updateWorkload(
          wafrArn,
          workloadcreation?.data?.createWorkload?.WorkloadId || "",
          target_arn,
          connectedAccounts,
        ),
      ]);
      const createmilestone = await Promise.all([
        createMilestone(
          wafrArn,
          workloadcreation?.data?.createWorkload?.WorkloadId || "",
          workloadname,
        ),
      ]);
      const highriskcount =
        createmilestone[0]?.data?.createMilestone?.RiskCounts?.HIGH;
      return { workloadcreation, updateanswer, highriskcount };
    }
    return { workloadcreation };
  };
  return { createWorkload };
};

export { useCreateWorkload };
