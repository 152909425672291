import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Heading from "../../common/Heading";
import FTRSelfAssessment from "./FTRSelfAssessment";
import useAccount from "../../../hooks/useAccount";

export default function FTRAutomation() {
  const { arn } = useAccount();
  return (
    <Container component="main">
      <Container maxWidth="lg">
        <Heading heading={"FTR Automation"} />
      </Container>
      <Box sx={{ width: "100%", mb:4 }}>
        <FTRSelfAssessment arn={arn}/>
      </Box>
    </Container>
  );
}
