import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import Heading from "../common/Heading";
import CrossAccount from "./create-account/CrossAccount";
import ManageAccounts from "./ManageAccounts";
import DeploymentEvents from "./DeploymentEvents";
import {
  useGetCognitoAccountQuery,
  useGetUserAccountsQuery,
} from "../../generated";
import LinkOrganisationAccounts from "./LinkOrganisationAccounts";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Accounts() {
  const { data, loading, refetch } = useGetUserAccountsQuery({
    notifyOnNetworkStatusChange: true,
  });

  const [value, setValue] = React.useState(0);
  const [tabSwitch, setTabSwitch] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data: getUserdata } = useGetCognitoAccountQuery({
    variables: {},
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (
      data &&
      Array.isArray(data?.getUserAccounts?.items) &&
      data?.getUserAccounts?.items.length > 0
    ) {
      setValue(1);
    } else {
      setValue(0);
    }
  }, []);

  return (
    <Container component="main">
      <TabPanel value={value} index={0}>
        <Heading heading={"Connect an AWS Account"} />
        <Typography color="text.primary" paragraph sx={{ width: "600px" }}>
          To deploy AUTOMATE+, please select the preferred deployment type and
          enter your account information. Please ensure that you are logged into
          the AWS account in this browser.
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Heading heading={"Connected Accounts"} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Heading heading={"Deployment Events"} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Heading heading={"LINK AWS ORGANISATION(S)"} />
      </TabPanel>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Deploy To AWS Account" {...a11yProps(0)} />
          <Tab label="Connected Accounts" {...a11yProps(1)} />
          <Tab label="Deployment Events" {...a11yProps(2)} />
          <Tab
            label="LINK AWS ORGANISATION Account/s with Continuous compliance"
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {loading ? (
          <></>
        ) : (
          <CrossAccount setValue={setValue} setTabSwitch={setTabSwitch} />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ManageAccounts
          setTabSwitch={setTabSwitch}
          tabSwitch={tabSwitch}
          data={data}
          loading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DeploymentEvents
          organisation={getUserdata?.getCognitoAccount?.organisation}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <LinkOrganisationAccounts
          setTabSwitch={setTabSwitch}
          tabSwitch={tabSwitch}
          data={data}
          loading={loading}
          refetch={refetch}
        ></LinkOrganisationAccounts>
      </TabPanel>
    </Container>
  );
}
