import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

import SharrDeployProgress from "../manage-accounts/SharrDeployProgress";
import { Box, CircularProgress, Stack, styled } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useAutomateProgressStatusQuery } from "../../generated";

interface AccountState {
  automateProgress: boolean;
  progressStatus: string;
  createdDate: string;
  rollbackOngoing: boolean;
  accountDeleted: boolean;
}

interface AccountProgressProps {
  arn: string;
  organisation: string;
  accountStates: Record<string, AccountState>;
  updateAccountState:(arn: string, newState: Partial<AccountState>) => void;
  setTabSwitch: any;
  tabSwitch: any;
}

const PassedText = styled(Typography)({
  color: "green",
  component: "body1",
  variant: "body1",
  align: "left",
});

const FailedText = styled(Typography)({
  color: "red",
  component: "body1",
  variant: "body1",
  align: "left",
});

const InfoText = styled(Typography)({
  color: "grey",
  component: "body1",
  variant: "body1",
  align: "left",
});

export default function AccountProgress({
  arn,
  organisation,
  accountStates,
  updateAccountState,
  setTabSwitch,
  tabSwitch,
}: AccountProgressProps) {
  const [userDeploying, setUserDeploy] = useState(false);
  const [display, setDisplay] = useState(true);
  const [progressValue, setProgressValue] = useState(0);

  let accountid = arn.split(":")[4];

  //Call the Automate Progress Query API
  const { data: DeploymentStatusData, refetch } =
    useAutomateProgressStatusQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        accountid: accountid,
        organisation: organisation,
      },
      context: {
        apiName: "user_deploy_process",
      },
      onCompleted: (ProgressStatusData) => {
        updateAccountState(arn, {
          progressStatus: ProgressStatusData?.automateProgressStatus?.status || "",
        });
      },
    });

  //Do Timezone conversion
  function timezone(date: any) {
    let localtime = new Date(date).toLocaleString();
    updateAccountState(arn, {
      createdDate: localtime,
    });
  }

  useEffect(() => {
    const executionarn = DeploymentStatusData?.automateProgressStatus?.executionArn || "";
    if (
      DeploymentStatusData &&
      DeploymentStatusData?.automateProgressStatus?.progress === 100
    ) {
      timezone(DeploymentStatusData?.automateProgressStatus?.created_at);
      setUserDeploy(false);
    }

    if (
      DeploymentStatusData &&
      DeploymentStatusData?.automateProgressStatus?.progress! < 100
    ) {
      //Set the account to be deleted if the rollback statemachine has been triggered
      if (
        DeploymentStatusData?.automateProgressStatus?.status === "ONGOING" &&
        executionarn.includes("user-app-rollback-state-machine")
      ) {
        updateAccountState(arn, {
          rollbackOngoing: true,
          accountDeleted: true,
        });
      }

      setDisplay(false);
      setUserDeploy(true);
      //Set the current progress value
      setProgressValue(DeploymentStatusData?.automateProgressStatus?.progress!);
    } else {
      setDisplay(true);
      setUserDeploy(false);
      setProgressValue(0);
    }

    //Trigger a refetch when a new account is deployed
    //and is being redirected from the connect account tab to the manage accounts tab
    if (tabSwitch === true) {
      refetch().then(() => {
        setTabSwitch(false);
      });
    }
  }, [DeploymentStatusData, tabSwitch]);

  function progressStatusCheck() {
    if (accountStates[arn]?.progressStatus === "COMPLETED") {
      return (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <CheckCircleOutlineOutlinedIcon
              sx={{ color: "green" }}
            ></CheckCircleOutlineOutlinedIcon>
            <Typography
              fontSize={"14px"}
              component={"span"}
              color={"success.main"}
              sx={{ ml: 0.5 }}
            >
              <PassedText>Completed</PassedText>
            </Typography>
          </Box>
        </>
      );
    } else if (accountStates[arn]?.progressStatus === "FAILED") {
      return (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <CancelOutlinedIcon sx={{ color: "red" }}></CancelOutlinedIcon>
            <Typography
              fontSize={"14px"}
              component={"span"}
              color={"error.main"}
              sx={{ ml: 0.5 }}
            >
              <FailedText>Failed</FailedText>
            </Typography>
          </Box>
        </>
      );
    } else if (accountStates[arn]?.progressStatus === "N/A") {
      return (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <InfoOutlinedIcon sx={{ color: "grey" }}></InfoOutlinedIcon>
            <Typography
              fontSize={"14px"}
              component={"span"}
              color={"info.main"}
              sx={{ ml: 0.5 }}
            >
              <InfoText>N/A</InfoText>
            </Typography>
          </Box>
        </>
      );
    }

    return (
      <>
        {userDeploying === true || display === false ? (
          <SharrDeployProgress progressValue={progressValue} />
        ) : null}
      </>
    );
  }

  return (
    <Stack direction="row">
      {accountStates[arn]?.progressStatus ? (
        <>{progressStatusCheck()}</>
      ) : (
        <CircularProgress color={"secondary"} />
      )}
    </Stack>
  );
}
