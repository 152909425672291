import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Container,
  Pagination,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DeleteUser from "./DeleteUser";
import EditUser from "./EditUserFc";
import {
  useGetCognitoUsersResultQuery,
  useCognitoAddUserMutation,
  useGetCognitoAccountQuery,
} from "../../generated";
import { useFormik } from "formik";
import * as yup from "yup";
import useAccount from "./../../hooks/useAccount";
import StyledTableCell from "../common/StyledTableCell";
import Heading from "../common/Heading";
import Loader from "../common/Loader";
import useSnackBars from "../../hooks/useSnackbar";
import useNewrelic from "../../hooks/useNewrelic";

const phoneRegExp = /^\+[0-9]+$/;

const validationSchema = yup.object({
  firstName: yup.string().required("Please add a First Name."),
  lastName: yup.string().required("Please add a Last Name."),
  emailAddress: yup
    .string()
    .email("Please add a valid email.")
    .required("Please add a valid email."),
  phoneNumber: yup
    .string()
    .matches(
      phoneRegExp,
      "Please enter a valid number with + and country code and no spaces"
    )
    .required(
      "Please enter a valid number with + and country code and no spaces"
    ),
});

const SaveButton = styled(LoadingButton)({
  width: "141px",
  height: "56px",
  textTransform: "none",
});

export default function ProfilePage() {
  const { arn } = useAccount();
  const { log } = useNewrelic();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const { setAlerts } = useSnackBars();
  const rowsPerPage = 4;

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      emailAddress: "",
      firstName: "",
      lastName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (submitValues, { resetForm }) => {
      cognitoAddUserMutation({
        variables: {
          arn: arn,
          first_name: submitValues.firstName,
          last_name: submitValues.lastName,
          email: submitValues.emailAddress,
          phone_number: submitValues.phoneNumber,
        },
      }).then((result) => {
        if (
          result.data?.cognitoAddUser?.message === "Successfully created user"
        ) {
          resetForm();
          refresh();
        } else {
          setAlerts([
            {
              severity: "error",
              msg: `Error: ${
                result.data?.cognitoAddUser?.message || "Unknown"
              }`,
            },
          ]);
        }
      });
    },
  });

  const refresh = () => {
    setUsers([]);
    return refetch({
      arn: arn,
    }).then(() => {
      log("Refetch Profile Page");
    });
  };

  const {
    data,
    loading: usersLoading,
    refetch,
  } = useGetCognitoUsersResultQuery({
    variables: {
      arn: arn,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: getUserdata } = useGetCognitoAccountQuery({
    variables: {},
    notifyOnNetworkStatusChange: true,
  });

  const [cognitoAddUserMutation, { loading }] = useCognitoAddUserMutation();

  useEffect(() => {
    if (!usersLoading && data) {
      let newUsers: any = data.getCognitoUsersResult?.users;
      setUsers(newUsers);
    }
  }, [usersLoading, data]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: "40px" }}>
      <Stack key={1} spacing={2}>
        <Heading
          heading={"Users in " + getUserdata?.getCognitoAccount?.organisation}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="body1"
            align="left"
            sx={{ fontWeight: "400px", width: "600px", height: "90px" }}
            color="#383737"
            gutterBottom
          >
            Create and manage users in your organisation.
          </Typography>
        </Stack>
      </Stack>
      <Stack key={2} spacing={6}>
        <Paper key={1} sx={{ width: "1216px", height: "490px" }}>
          <Grid
            container
            direction="column"
            justifyContent="left"
            alignItems="left"
            padding="32px"
            spacing={2}
          >
            <Grid item xs={4}>
              <Typography
                component="h6"
                variant="h6"
                sx={{ fontWeight: "bold" }}
                align="left"
                color="#383737"
                gutterBottom
              >
                Users
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TableContainer sx={{ height: "350px" }} component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Given Name</StyledTableCell>
                      <StyledTableCell>Family Name</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>Edit</StyledTableCell>
                      <StyledTableCell>Delete</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersLoading && (
                      <TableRow>
                        <StyledTableCell colSpan={5}>
                          <Loader />
                        </StyledTableCell>
                      </TableRow>
                    )}
                    {!usersLoading &&
                      users
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((user: any) => {
                          return (
                            <>
                              <TableRow key={user.username}>
                                <StyledTableCell>
                                  {user.given_name}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {user.family_name}
                                </StyledTableCell>
                                <StyledTableCell>{user.email}</StyledTableCell>
                                <StyledTableCell>
                                  <EditUser
                                    disabled={
                                      user.email !==
                                      getUserdata?.getCognitoAccount
                                        ?.emailAddress
                                    }
                                    email={user.email}
                                  />
                                </StyledTableCell>
                                <StyledTableCell>
                                  <DeleteUser
                                    disabled={
                                      user.email ===
                                      getUserdata?.getCognitoAccount
                                        ?.emailAddress
                                    }
                                    arn={arn}
                                    email={user.email}
                                    username={user.username}
                                    refresh={refresh}
                                  />
                                </StyledTableCell>
                              </TableRow>
                            </>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={users ? Math.ceil(users.length / rowsPerPage) : 0}
                variant="outlined"
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: "10px",
                  color: "primary.main",
                  borderColor: "primary.main",
                }}
                shape="rounded"
                page={page + 1}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  value: number
                ) => {
                  setPage(value - 1);
                }}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper key={2} sx={{ width: "1216px", height: "380px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction="column"
              justifyContent="left"
              alignItems="left"
              padding="32px"
              spacing={4}
            >
              <Grid item xs={4}>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{ fontWeight: "bold" }}
                  align="left"
                  color="#383737"
                  gutterBottom
                >
                  Add new Users
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    sx={{ width: "373.33px", height: "56px" }}
                    id="firstName"
                    name="firstName"
                    label="First name"
                    variant="outlined"
                    value={formik.values.firstName}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    onChange={formik.handleChange}
                  />
                  <TextField
                    sx={{ width: "373.33px", height: "56px" }}
                    id="lastName"
                    name="lastName"
                    label="Last name"
                    variant="outlined"
                    value={formik.values.lastName}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    onChange={formik.handleChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    sx={{ width: "373.33px", height: "56px" }}
                    id="emailAddress"
                    name="emailAddress"
                    label="Email"
                    variant="outlined"
                    value={formik.values.emailAddress}
                    error={
                      formik.touched.emailAddress &&
                      Boolean(formik.errors.emailAddress)
                    }
                    helperText={
                      formik.touched.emailAddress && formik.errors.emailAddress
                    }
                    onChange={formik.handleChange}
                  />
                  <TextField
                    sx={{ width: "373.33px", height: "56px" }}
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone Number (+61 etc. format)"
                    variant="outlined"
                    value={formik.values.phoneNumber}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                    onChange={formik.handleChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <SaveButton
                  loading={loading}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Save
                </SaveButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Stack>
    </Container>
  );
}
