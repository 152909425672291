import { FC, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import StyledTableCell from "../../common/StyledTableCell";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import HighRiskNumbers from "../common/HighRiskNumbers";
import { useGetWorkloadQuery, useListMilestonesQuery } from "../../../generated";
import MilestoneNumber from "../common/MilestoneNumber";
import DownloadReports from "../common/DownloadReports";
import DeleteWorkload from "../common/DeleteWorkload";
import UpdateButton from "../common/UpdateButton";

interface Workload {
  WorkloadId: string;
  WorkloadArn: string;
  WorkloadName: string;
  Owner: string;
  UpdatedAt: string;
  Lenses: [string];
  RiskCounts: {
    UNANSWERED: number,
    HIGH: number,
    MEDIUM: number,
    NONE: number,
    NOT_APPLICABLE: number,
  };
  ImprovementStatus: string;
  AwsCreditAccount: string;
  AwsWafrAccount: string;
  AwsTechnicalReviewAccount: string[];
}

interface ListWorkLoadItemProps {
  arn: string;
  workload: Workload;
  updateWorkLoad: (
    workLoadId: string,
    workLoadName: string,
    highRiskCount: number,
    initialHighRiskCount: number,
    awsWafrAccount: string,
  ) => void;
}

const ListWorkLoadIacItem: FC<ListWorkLoadItemProps> = ({
  arn,
  workload,
  updateWorkLoad,
}: ListWorkLoadItemProps) => {
  const [open, setOpen] = useState(false);
  const [initialHighRiskCount, setInitialHighRiskCount] = useState(0);

  const { data, loading, refetch } = useListMilestonesQuery({
    variables: {
      arn,
      workloadid: workload.WorkloadId,
    },
    context: {
      apiName: "well_architected",
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: getWorkloadData, loading: getWorkloadLoading } = useGetWorkloadQuery({
    variables: {
      arn,
      WorkloadId: workload.WorkloadId,// value for 'WorkloadId'
    },
    context: {
      apiName: "well_architected",
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const firstHighRiskCount: number = Math.max(
      ...(data?.listMilestones?.MilestoneSummaries.map(
        (milestone) => milestone.RiskCounts?.HIGH || 0
      ) || [0, 0])
    );

    setInitialHighRiskCount(firstHighRiskCount);
  }, [data, loading]);

  useEffect(() => {
    refetch({arn, workloadid: workload.WorkloadId});
  }, [refetch, arn, workload]);

  //Workload Dropdown Information
  const WorkloadDetails = ({
    workload,
    getWorkloadData,
  }: {
    workload: Workload;
    getWorkloadData: any;
  }) => (
    <Box sx={{ margin: 1, display: "block" }}>
      <Typography variant="body1" align="left" gutterBottom>
        <b>AWS WAFR Account:</b> {workload.AwsWafrAccount}
      </Typography>
      <Typography variant="body1" align="left" gutterBottom>
        <b>AWS Credit Account:</b> {workload.AwsCreditAccount}
      </Typography>
      <Typography variant="body1" align="left" gutterBottom>
        <b>AWS Technical Review Account:</b> {
          Array.isArray(workload.AwsTechnicalReviewAccount) 
          ? workload.AwsTechnicalReviewAccount.join(", ") 
          : "N/A"}
      </Typography>
      <Typography variant="body1" align="left" gutterBottom>
        <b>Lenses:</b> Well-Architected
      </Typography>
      <Typography variant="body1" align="left" gutterBottom>
        <b>AWS Regions:</b> {getWorkloadData?.getWorkload?.AwsRegions?.[0] || "N/A"}
      </Typography>
      <Typography variant="body1" align="left" gutterBottom>
        <b>Workload Id:</b> {getWorkloadData?.getWorkload?.WorkloadId || "N/A"}
      </Typography>
    </Box>
  );

  return (
    <>
      <TableRow>
        <StyledTableCell>{workload.WorkloadName}</StyledTableCell>
        <StyledTableCell>{new Date(workload.UpdatedAt).toLocaleString("en-GB")}</StyledTableCell>
        <StyledTableCell align="center">
          <MilestoneNumber
            arn={arn}
            workloadid={workload.WorkloadId}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <HighRiskNumbers highrisknumber={workload.RiskCounts.HIGH} loading={loading} />
        </StyledTableCell>
        <StyledTableCell align="center">
          {getWorkloadLoading ? 
            <CircularProgress size={15} />
            :
            <UpdateButton
              arn={arn}
              workloadid={workload.WorkloadId}
              milestonename={workload.WorkloadName}
              updateWorkLoad={() => {
                updateWorkLoad(
                  workload.WorkloadId,
                  workload.WorkloadName,
                  workload.RiskCounts.HIGH,
                  initialHighRiskCount,
                  arn,
                );
              }}
            />
          }
        </StyledTableCell>
        <StyledTableCell align="center">
          <DownloadReports
            arn={arn}
            workloadname={workload.WorkloadName}
            workloadid={workload.WorkloadId}
            workloadlens={"wellarchitected"}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <DeleteWorkload
            arn={arn}
            workloadid={workload.WorkloadId}
            workloadname={workload.WorkloadName}
            workloadType="IaC"
          />
        </StyledTableCell>
        <StyledTableCell>
          <IconButton color="secondary" onClick={() => setOpen(!open)}>
            {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <WorkloadDetails
              workload={workload}
              getWorkloadData={getWorkloadData}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ListWorkLoadIacItem;
