import { useSubmitEmailMutation } from "../generated";

interface SubmitEmailInput {
    wafrArn: string;
    emailSubmitted: any;
    setEmailSubmitted: any;
}

interface EmailSubmittedDictionary {
    [key: string]: boolean;
}

const useSubmitEmail = ({
   wafrArn, emailSubmitted, setEmailSubmitted
}: SubmitEmailInput) => {
    const [submitEmailMutation] = useSubmitEmailMutation();

    const addEmailSubmittedKeyValuePairs = (newPairs: EmailSubmittedDictionary) => {
        setEmailSubmitted((emailSubmitted: any) => ({
          ...emailSubmitted,
          ...newPairs
        }));
    };

    const submitemail_mutation = async (workloadid: string) => {
        if(emailSubmitted[workloadid] === false){
            addEmailSubmittedKeyValuePairs({[workloadid]:true});
            const submitemail = await submitEmailMutation({
                variables: {
                  arn: wafrArn,
                  workLoadId: workloadid,
                  stage: "HALF_STAGE",
                },
                context: {
                  apiName: "well_architected",
                },
            });
            if(submitemail?.data?.submitEmail?.status !== "COMPLETE"){
                addEmailSubmittedKeyValuePairs({[workloadid]:false});
            }
            return {submitemail}    
        }
        return false;
    };
    return {submitemail_mutation};
};

export { useSubmitEmail };
