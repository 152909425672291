import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { useValidateAccountQuery } from "../../generated";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Box, CircularProgress, Typography, styled } from "@mui/material";


interface AccountState {
  automateProgress: boolean;
  progressStatus: string;
  createdDate: string;
  rollbackOngoing: boolean;
  accountDeleted: boolean;
}

interface TestAccountProps {
  arn: string;
  accountStates: Record<string, AccountState>;
}

const PassedText = styled(Typography)({
  color: "green",
  component: "body1",
  variant: "body1",
  align: "left",
});

const FailedText = styled(Typography)({
  color: "red",
  component: "body1",
  variant: "body1",
  align: "left",
});

export default function TestAccount({
  arn,
  accountStates,
}: TestAccountProps) {
  const [countdown, setCountdown] = useState(0);
  //Check if the six pillars role is on the customers account
  const {data, refetch} = useValidateAccountQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      arn: arn, // value for 'template'
    },
  });

  useEffect(() => {
    if(accountStates[arn]?.accountDeleted === false){
      refetch();
    }
    if (countdown === 6) {
      refetch();
    }
  }, [countdown, refetch, accountStates[arn]?.progressStatus]);

  function validCheck() {
    if (data?.validateAccount?.msg === "success") {
      return (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <CheckCircleOutlineOutlinedIcon sx={{ color: "green" }}></CheckCircleOutlineOutlinedIcon>
            <Typography fontSize={"14px"} component={"span"} color={"success.main"} sx={{ ml: 0.5 }}>
              <PassedText>Yes</PassedText>
            </Typography>
          </Box>
        </>
      );
    } else {
      timecount();
    }
    return (
      <>
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <CancelOutlinedIcon sx={{ color: "red" }}></CancelOutlinedIcon>
          <Typography fontSize={"14px"} component={"span"} color={"error.main"} sx={{ ml: 0.5 }}>
            <FailedText>No</FailedText>
          </Typography>
        </Box>
      </>
    );
  }


  function addCountdown(countdown:number){
    let count = countdown + 1;
    setCountdown(count);
  }

  function timecount(){
    const timer = setTimeout(() => addCountdown(countdown), 30000);
    return () => clearTimeout(timer);
  }

  return (
    <Stack direction="row">
      {data ? (
        <>
          {validCheck()}
        </>
      ) : (
        <>
          {timecount() ? (
            <CircularProgress color={"secondary"} />
          ):(
            <></>
          )}
        </>
      )}
    </Stack>
  );
}
